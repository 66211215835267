import { Group, Paper, Space, Tabs, Text, createStyles } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useScreenDetector } from "../screen-detector";
import { Tab } from "@mantine/core/lib/Tabs/Tab/Tab";
import { currencyFormat } from "../../utils";
import dayjs from "dayjs";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function FundingInformationChart({ data, filter, setFilter }: { data?: any; filter: any; setFilter: any }) {
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();

  const getOptions = (isDummi: boolean) => ({
    chart: {
      zoomType: "xy",
      // type: "bar",
    },
    title: {
      text: "",
      align: "bottom",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories:
          data?.byInterval?.map((item: any) => {
            return item.date;
          }) || [],
        crosshair: true,
      },
    ],
    // yAxis: [
    //   {
    //     labels: {
    //       format: "{value}",
    //       style: {
    //         color: "gray",
    //       },
    //     },
    //     title: {
    //       text: "",
    //       style: {
    //         color: "green",
    //       },
    //     },
    //   },
    //   {
    //     title: {
    //       text: "",
    //       style: {
    //         color: "black",
    //       },
    //     },
    //     labels: {
    //       format: "{value}",
    //       style: {
    //         color: "gray",
    //       },
    //     },
    //     opposite: true,
    //   },
    // ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      // x: 80,
      // verticalAlign: "top",
      y: 20,
      // floating: true,
      // backgroundColor: "rgba(255,255,255,0.25)",
    },
    series: [
      {
        name: "Авсан дүн",
        color: theme.colors.golomt[0],
        type: "column",
        data: isDummi
          ? [20, 100, 300, 240, 40, 60, 20, 40, 79, 10, 80, 180].map((item) => {
              return getRandomInt(item);
            })
          : data?.byInterval?.map((item: any) => {
              return item.financedAmount;
            }) || [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Хүссэн дүн",
        type: "column",
        color: theme.colors.golomt[6],
        data: isDummi
          ? [50, 200, 400, 260, 50, 60, 20, 40, 99, 150, 80, 280].map((item) => {
              return getRandomInt(item);
            })
          : data?.byInterval?.map((item: any) => {
              return item.requestedAmount;
            }) || [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Олгосон нэхэмжлэх тоо",
        type: "spline",
        color: theme.colors.orange[6],
        data: isDummi
          ? [50, 200, 400, 260, 50, 60, 20, 40, 99, 150, 80, 280].map((item) => {
              return getRandomInt(item);
            })
          : data?.byInterval?.map((item: any) => {
              return item.financedCount;
            }) || [],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Хүссэн нэхэмжлэх тоо",
        type: "spline",
        color: theme.colors.blue[9],
        data: isDummi
          ? [50, 200, 400, 260, 50, 60, 20, 40, 99, 150, 80, 280].map((item) => {
              return getRandomInt(item);
            })
          : data?.byInterval?.map((item: any) => {
              return item.requestedCount;
            }) || [],
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  return (
    <Paper withBorder py={"xs"}>
      <Group position="apart">
        <Group align="center" noWrap>
          <div className={classes.grdnt}></div>
          <Text fw={600}>Санхүүжилтийн мэдээлэл</Text>
        </Group>{" "}
        <DateRangePicker
          excludeDate={(date) => {
            return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
          }}
          value={[new Date(filter.startDate), new Date(filter.endDate)]}
          onChange={(e) => {
            if (e[0] && e[1])
              setFilter({
                ...filter,
                startDate: e[0],
                endDate: e[1],
              });
            else if (!e[0] && !e[1]) {
              setFilter({
                ...filter,
                startDate: dayjs(new Date()).subtract(14, "day").format(),
                endDate: dayjs(new Date()).format(),
              });
            }
          }}
          size="xs"
          style={{ borderRadius: "5px" }}
          variant="unstyled"
          bg={theme.colors.golomt[0]}
          miw={isMobile ? "100%" : "240px"}
          placeholder="Огноо хайх"
          icon={<IconCalendar size={"1.2rem"} />}
          inputFormat="YYYY-MM-DD"
          mr={isMobile ? 0 : "sm"}
        />
      </Group>
      <Space h={"md"} />
      <Paper px={"lg"}>
        <Tabs defaultValue={"1"} variant="pills">
          <Tabs.List>
            <Tabs.List>
              <Tabs.Tab value="1">Жишээ</Tabs.Tab>
              <Tabs.Tab value="2">Бодит</Tabs.Tab>
            </Tabs.List>
          </Tabs.List>
          <Tabs.Panel value="1">
            <div>
              <Space h={"lg"} />
              <HighchartsReact highcharts={Highcharts} options={getOptions(true)} />
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="2">
            <Space h={"lg"} />
            <HighchartsReact highcharts={Highcharts} options={getOptions(false)} />
          </Tabs.Panel>
        </Tabs>

        <Group position="right" style={{ marginTop: -20 }}>
          <Text size={"sm"} color="dimmed" style={{ zIndex: 2 }} bg={"white"}>
            Хэмжих нэгж: Сая төгрөгөөр
          </Text>
        </Group>
      </Paper>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.fn.linearGradient(45, theme.colors.golomt[3], theme.colors.golomt[6]),
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
