import { Paper, Text } from "@mantine/core";
import { useState } from "react";
import { RepaymentApi } from "../../apis";
import { RemimberHistoryList } from "../remimber-history-list";

export function RememberHistory({ action }: { action: any }) {
  const [filters, setFilters] = useState<any>({ repaymentId: action[1]?.id, isEmail: false });

  return (
    <Paper>
      <Text my={"md"} fw={600}>
        Өнөөдөр
      </Text>

      {action[1]?.id ? (
        <RemimberHistoryList
          name="remembering.history"
          filters={{ ...filters, repaymentId: action[1].id! }}
          loadData={(data) => RepaymentApi.reminder_history(data!)}
        />
      ) : (
        ""
      )}
    </Paper>
  );
}
