import { ActionIcon, Box, Drawer, Flex, Group, Paper, Space, Tabs, Text, createStyles } from "@mantine/core";
import { IconFileInvoice, IconSend, IconSquareArrowLeft } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ScfRequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { RequestBuyerLBF } from "./buyer-list";
import { LbfRequestTabBuyer } from "./lbf-request-tab-buyer";
import { LbfRequestTabSupplier } from "./lbf-request-tab-supplier";
import { RequestInvoiceTab } from "./request-invoice-tab";
import { RequestSupplierLBF } from "./supplier-list";

export const RequestFundingLendLineList = () => {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { theme } = useStyle();
  const [tabKey, setTabKey] = useState<string>("1");

  const getInvoiceLbf = async (id: string) => {
    setIsLoading(true);
    try {
      let res = await ScfRequestApi.get(id, "lbf");
      setAction(["details", res]);
      setTabKey("1");
    } catch (error: any) {
      console.log("error: ", error);
      error?.message && message.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Paper>
      {user?.currentBusiness?.type === "BUYER" && <RequestSupplierLBF getInvoiceLbf={getInvoiceLbf} />}
      {user?.currentBusiness?.type === "SUPPLIER" && <RequestBuyerLBF getInvoiceLbf={getInvoiceLbf} />}
      <Drawer
        withCloseButton={false}
        position="right"
        title={false}
        padding={"lg"}
        size={"100rem"}
        opened={action[0] === "details"}
        onClose={() => setAction([])}>
        <Box>
          <Form initialValues={{ requestedAmount: action[1]?.amountToPay }} onSubmit={() => {}}>
            {({ values }) => {
              return (
                <div>
                  <Group w={"100%"} position="apart">
                    <Flex align="center" gap={"xs"}>
                      <ActionIcon color="golomt" onClick={() => setAction([])}>
                        <IconSquareArrowLeft />
                      </ActionIcon>
                      <Text fw={700}>Нэхэмжлэх</Text>
                      <Text color="golomt" fw={700}>
                        # {action[1]?.refCode}
                      </Text>
                    </Flex>
                  </Group>
                  <Space mt={"lg"} />
                  <Paper p={"lg"} withBorder>
                    <Tabs onTabChange={(e: string) => setTabKey(e)} value={tabKey}>
                      <Tabs.List>
                        <Tabs.Tab value="1">
                          <Flex align="center" gap={"xs"}>
                            <IconFileInvoice color={tabKey === "1" ? theme.colors.golomt[6] : theme.colors.gray[7]} />
                            <Text fw={500} color={tabKey === "1" ? "golomt" : theme.colors.gray[7]}>
                              Нэхэмжлэх
                            </Text>
                          </Flex>
                        </Tabs.Tab>
                        <Tabs.Tab value="2">
                          <Flex align="center" gap={"xs"}>
                            <IconSend color={tabKey === "2" ? theme.colors.golomt[6] : theme.colors.gray[7]} />
                            <Text fw={500} color={tabKey === "2" ? "golomt" : theme.colors.gray[7]}>
                              Хүсэлт
                            </Text>
                          </Flex>
                        </Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="1">{action[1] && <RequestInvoiceTab action={action} />}</Tabs.Panel>
                      <Tabs.Panel value="2">
                        {user?.currentBusiness?.type === "SUPPLIER" && (
                          <LbfRequestTabSupplier action={action && action} values={{ requestedAmount: values.requestedAmount }} />
                        )}
                        {user?.currentBusiness?.type === "BUYER" && (
                          <LbfRequestTabBuyer action={action && action} values={{ requestedAmount: values.requestedAmount }} />
                        )}
                      </Tabs.Panel>
                    </Tabs>
                  </Paper>
                </div>
              );
            }}
          </Form>
        </Box>
      </Drawer>
    </Paper>
  );
};

const useStyle = createStyles({});
