import { ActionIcon, Divider, Flex, Group, LoadingOverlay, Pagination, Paper, Text, createStyles } from "@mantine/core";
import { IconInfoSquare, IconMailFast, IconMessageChatbot, IconPhone } from "@tabler/icons";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";

type Props = {
  name: string;
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  minWidth?: number;
};

export type ITableRef = { reload: () => void };

type IData = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  repaymentId: string;
  receiverUserId: string;
  isExistUser: boolean;
  phone: string;
  email: string;
  remindedUserId: string;
  type: string;
  reminderType: string;
  reminderLevel: string;
  connectType: string;
  result: string;
  response: string;
  clientText: string;
  receiverUser: {
    id: string;
    lastName: string;
    firstName: string;
    avatar: null | string;
  };
};

export const RemimberHistoryList = React.forwardRef(
  ({ name, filters = {}, pagination = true, dataSource = [], loadData }: Props, ref: React.Ref<ITableRef>) => {
    const { classes } = useStyles();

    const { reminderConnectTypes } = useSelector((state: { general: IGeneral }) => state.general);

    const [offset, setOffset] = React.useState({ page: 1, limit: 20 });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    const rndrIcon = (code: string) => {
      switch (code) {
        case "PHONE":
          return <IconPhone />;
        case "SMS":
          return <IconMailFast />;
        case "IN_PERSON":
          return <IconMessageChatbot />;
        default:
          return <IconInfoSquare />;
      }
    };

    return (
      <div className={classes.container}>
        {data?.rows?.map((item: IData | any, index: number) => {
          return (
            <div key={index}>
              <Flex pt={"sm"}>
                <Flex px="sm" h={"100%"}>
                  <ActionIcon color={reminderConnectTypes.find((item2: IReference) => item2.code === item.connectType)?.color || "orange"}>
                    {rndrIcon(`${item.connectType}`)}
                  </ActionIcon>
                </Flex>
                <Paper w="100%">
                  <Group align="center" position="apart" w="100%">
                    <Text color={reminderConnectTypes.find((item2: IReference) => item2.code === item.connectType)?.color || "orange"} fz={"lg"} fw={600}>
                      {reminderConnectTypes.find((item2: IReference) => item2.code === item.connectType)?.name || "-"}
                    </Text>
                    <Text color={reminderConnectTypes.find((item2: IReference) => item2.code === item.connectType)?.color || "orange"} fz={"sm"} fw={600}>
                      {item.type}
                    </Text>
                  </Group>
                  <Flex w="100%" align="end" wrap="wrap" gap={8} justify="space-between">
                    <Text maw={"600px"} fz={"sm"}>
                      {item.clientText}
                    </Text>
                    <Text color="dimmed" fz={"sm"} w={"max-content"} align="end">
                      {dateTimeFormat(item.createdAt)}
                    </Text>
                  </Flex>
                </Paper>
              </Flex>
              <Divider mt={"sm"} variant="dashed" />
            </div>
          );
        })}
        {data?.rows?.length === 0 && (
          <Flex h={"xl"} p={"xl"} align="center" justify="center">
            <Text color="gray">Мэдээлэл байхгүй.</Text>
          </Flex>
        )}
        {pagination && (
          <Group position="right" py="sm">
            <Pagination
              size="sm"
              page={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
            />
          </Group>
        )}
        <LoadingOverlay visible={isLoading} />
      </div>
    );
  },
);

const useStyles = createStyles((theme) => ({
  height: {
    height: "50px",
  },
  container: {},
}));
