import { Popover, Text } from "@mantine/core";

export function PopoverText({
  children,
  withArrow = true,
  position = "top",
  width = 220,
}: {
  children: string;
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
  width?: number;
}) {
  if (children && children.length > 18)
    return (
      <Popover width={"max-content"} position={position} withArrow={withArrow} shadow="sm">
        <Popover.Target>
          <Text component="a" underline w={"max-conent"}>
            {children?.substring(0, 18) || ""}...
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">{children}</Text>
        </Popover.Dropdown>
      </Popover>
    );
  else return <Text underline>{children}</Text>;
}
