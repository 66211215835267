import { Avatar, Header as BaseHeader, Box, Burger, Center, Flex, Group, Text, createStyles } from "@mantine/core";
import { IconPhoto } from "@tabler/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthApi } from "../../apis";
// import { ReactComponent as Golomt } from "../../assets/golomt.svg";
// import { ReactComponent as BogdWhite } from "../../assets/bogd-logo-teal.svg";
import BogdWhite from "./../../assets/bogd-logo-teal.png";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { authChange, authMe } from "../../store/Auth";
import { message } from "../../utils/Message";
import { useScreenDetector } from "../screen-detector";

type INotification = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  type: string;
  receiverType: string;
  receiverId: string;
  title: string;
  body: string;
  isSeen: boolean;
  seenDate: string | null;
  logo: string;
};

export const HeaderLogin = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes, theme } = useStyles();
  const [seenType, setSeenType] = useState<"ALL" | "SEEN" | "UNSEEN">("ALL");
  const { notificationTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [openNofity, setOpenNotify] = useState<boolean>(false);
  const { isMobile, isTablet } = useScreenDetector();

  const dispatch = useDispatch();

  const logout = async () => {
    try {
      await AuthApi.logout();
      dispatch(authChange());
      dispatch(authMe({} as any));
      window.location.href = "https://dev-cp.dehub.mn";
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const nofity = (item: INotification, index: number) => {
    return (
      <Flex gap={12} key={index} mb={"lg"}>
        <Avatar src={item.logo} size={"lg"} radius={"xl"}>
          <IconPhoto />
        </Avatar>
        <Flex direction="column" w={"100%"} gap={8}>
          <Flex w={"100%"} align="center" justify="space-between">
            <Text color="violet.9" fw={700}>
              {item.title}
            </Text>
            <Text w={200} truncate color="violet" fz={"sm"} fw={600}>
              {notificationTypes?.find((item2: IReference) => item2.code === item.type)?.name || item.type}
            </Text>
          </Flex>
          <>
            <Text fz={"sm"}>{item.body}</Text>
            <Text color="violet" fz={"xs"} fw={700}>
              {dayjs(item.createdAt).format("HH:mm")}
            </Text>
          </>
        </Flex>
      </Flex>
    );
  };

  return (
    <BaseHeader height={{ base: 68, md: 68 }} className={classes.headerWrap}>
      <Group className={classes.header}>
        {!isMobile ? (
          <Flex align={"center"} gap={8}>
            <a href="https://dev-cp.dehub.mn" className={classes.logoBox}>
              <Center className={classes.logo}>
                {/* <BogdWhite /> */}
                <img src={BogdWhite} alt="" height={40} />
              </Center>
            </a>
            {!isTablet && (
              <>
                <Box h={"32px"} style={{ borderRight: "1px solid #868E96" }}></Box>
                <Text className={classes.moduleText}>САНХҮҮЖИЛТ УДИРДЛАГА</Text>
              </>
            )}
          </Flex>
        ) : (
          <div>
            <Flex p={"xs"}>
              <Burger color="gray" size={"sm"} opened={opened} onClick={() => setOpened(!opened)} />
            </Flex>
          </div>
        )}
      </Group>
    </BaseHeader>
  );
};

const useStyles = createStyles((theme) => ({
  headerWrap: {
    background: "#317169",
    paddingBottom: 4,
    borderBottom: 0,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: "#317169",
    height: 64,
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
    gap: "12px",
  },
  logo: {
    height: 30,
  },
  logoText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.white,
  },
  user: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "8px",
    justifyContent: "space-between",
    color: "#fff",
    "&:hover": {
      opacity: 0.4,
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: "white",
    textTransform: "uppercase",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
