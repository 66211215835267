import { HttpRequest } from "../utils";
const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  const res = await httpRequest.get("/api/program", data);
  return res;
};

export const select = async (data: { invIds: string[]; productType: "BUYER_LED" | "SUPPLIER_LED" }) => {
  const res = await httpRequest.put("/api/program/select", data);
  return res;
};

export const getScf = async (id: string) => {
  const res = await httpRequest.get(`/api/program/scf/${id}`);
  return res;
};
