import { Input, TextInput } from "@mantine/core";
import { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  onChangeCustom?: any;
  rightSection?: React.ReactNode;
  classNames?: Partial<Record<BaseSelectStylesNames, string>> | undefined;
  size?: any;
  variant?: any;
  className?: any;
  styles?: any;
  radius?: any;
  required?: boolean;
  icon?: any;
  rightSectionWidth?: number;
};

export function TextField({
  name,
  label,
  placeholder,
  disabled = false,
  onChangeCustom,
  rightSection,
  classNames,
  size,
  variant,
  className,
  styles,
  radius,
  required = false,
  icon,
  rightSectionWidth,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Input.Wrapper label={label} size={size} required={required}>
      <TextInput
        error={error}
        variant={variant}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        size={size}
        radius={radius}
        onChange={(e) => {
          onChange(e.target.value);
          onChangeCustom && onChangeCustom(e.target.value);
        }}
        className={className}
        required={required}
        styles={styles}
        icon={icon}
        rightSection={rightSection}
        rightSectionWidth={rightSectionWidth}
      />
    </Input.Wrapper>
  );
}
