import { Center, Flex, Grid, Group, Paper, RingProgress, Select, Space, Text, createStyles } from "@mantine/core";
import { currencyFormat } from "../../utils";

export const ProgramLimitChart = ({ data }: { data: any }) => {
  const { classes } = useStyles();

  return (
    <div>
      <Paper withBorder py={"xs"}>
        <Group position="apart">
          <Group align="center" noWrap>
            <div className={classes.grdnt}></div>
            <Text fw={600}>Хөтөлбөрийн лимит</Text>
          </Group>
          <Select w={"220px"} data={[]} placeholder="Сонгосон хөтөлбөрийн нэр " size="xs" variant="filled" mr={"sm"} />
        </Group>
        <Space h={"md"} />
        <Grid align="center">
          <Grid.Col sm={12} md={6}>
            <Center>
              <RingProgress
                thickness={30}
                size={350}
                label={<Text size="xs" ta="center"></Text>}
                sections={
                  data?.byProgram?.map((item: any) => {
                    return {
                      name: item.name,
                      color: item.color,
                      amount: item.amount,
                      invoice: `${item.count}  ш нэхэмжлэх`,
                      value: item.count,
                    };
                  }) || []
                }
              />
            </Center>
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Flex w={"100%"} direction={"column"} gap={8} px={"xl"}>
              {data?.byProgram?.map((item: any, index: number) => {
                return (
                  <Flex gap={"sm"} key={index}>
                    <Paper m={"xs"} radius={10} maw={"10px"} miw={"10px"} mah={"10px"} mih={"10px"} bg={item.color}></Paper>
                    <Flex justify={"space-between"} w={"100%"}>
                      <div>
                        <Text fw={600}>{item.name}</Text>
                        <Text color="dimmed" fz={"sm"}>
                          {item.count} ш нэхэмжлэх
                        </Text>
                      </div>
                      <Text fw={600}>{currencyFormat(item.count)}</Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  );
};

const chartData = [
  {
    name: "Санхүүжилт авсан",
    color: "green",
    amount: "823,020",
    invoice: "1,348 ш нэхэмжлэх",
    value: getRandomInt(20),
  },
  {
    name: "Санхүүжилт хүссэн",
    color: "#45A6E9",
    amount: "823,020",
    invoice: "1,348 ш нэхэмжлэх",
    value: getRandomInt(20),
  },
  {
    name: "Олголт хүлээж буй",
    color: "yellow",
    amount: "823,020",
    invoice: "1,348 ш нэхэмжлэх",
    value: getRandomInt(20),
  },
  {
    name: "Боломжит лимит",
    color: "orange",
    amount: "823,020",
    invoice: "1,348 ш нэхэмжлэх",
    value: getRandomInt(20),
  },
  {
    name: "Үлдэгдэл лимит",
    color: "red",
    amount: "823,020",
    invoice: "1,348 ш нэхэмжлэх",
    value: getRandomInt(20),
  },
];

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.fn.linearGradient(45, theme.colors.golomt[3], theme.colors.golomt[6]),
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
