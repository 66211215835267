import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => await httpRequest.get("/api/financeable", data);

// export const get = async (id: string, programId: string) => await httpRequest.get(`/api/financeable/${id}/program/${programId}`);

export const buyerSelect = async (data: any) => await httpRequest.get("/api/financeable/buyer_select", data);

export const programList = async (data: any) => await httpRequest.get("/api/financeable/program", data);

export const getScf = async (id: string, programId: string) => await httpRequest.get(`/api/financeable_scf/${id}/program/${programId}`);

export const getLbf = async (id: string, programId: string) => await httpRequest.get(`/api/financeable_lbf/${id}/program/${programId}`);
