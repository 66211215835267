import { createSlice } from "@reduxjs/toolkit";

type IBasic = {
  selectedPrograms: any[];
  lbfProgram: any;
};

const initialState: IBasic = {
  selectedPrograms: [],
  lbfProgram: null,
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    selectedProgramsReduce: (state, { payload }: { payload: any }) => {
      state.selectedPrograms = payload;
      return state;
    },
    getLbfProgramReduce: (state, { payload }: { payload: any }) => {
      state.lbfProgram = payload;
      return state;
    },
  },
});

export const programsReducer = programsSlice.reducer;

export const { selectedProgramsReduce, getLbfProgramReduce } = programsSlice.actions;
