import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/api/auth/me");
  return res;
};

export const login = async (data: any) => {
  const res = await httpRequest.post("/api/auth/login", data);
  return res;
};

export const logout = async () => {
  const res = await httpRequest.get("/api/auth/logout");
  return res;
};

export const verify = async (verifyId: string) => await httpRequest.get(`/api/auth/${verifyId}/verify`);

// Folder: otp =>

export const otpGet = async (data: { otpMethod: string }) => await httpRequest.get(`/api/auth/otp`, { otpMethod: data.otpMethod });

export const otpVerify = async (data: { otpMethod: string; otpCode: string }) => await httpRequest.post(`/api/auth/otp/verify`, data);

export const phoneCheck = async (data: { otpMethod: string; phone: string }) => await httpRequest.get(`/api/auth/otp/phone_check`, data);

export const registerVerify = async (data: { verifyId: string; otpCode: string }) => await httpRequest.post(`/api/auth/otp/register_verify`, data);

// Folder: password =>

export const password = async (data: { password: string; oldPassword: null | string }) => await httpRequest.post(`/api/auth/password/change`, data);

export const forgot = async (data: any) => {
  const res = await httpRequest.put("/api/auth/forgot", data);
  return res;
};
