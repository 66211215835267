import { Tabs } from "@mantine/core";
import { useState } from "react";
import { FinanceLimit } from "../../components/finance-program/finance-limit";
import { ProgramBuyer } from "../../components/finance-program/program-buyer";
import { FinanceConditions } from "../../components/finance-program/finance-conditions";
import { PageLayout } from "../../components/layout";
import useSWR from "swr";
import { ScfProgramApi } from "../../apis";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { ProgramBuyerAnchor } from "../../components/finance-program/program-buyer-anchor";

export const FinanceProgramDetails = () => {
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const [filters, setFilters] = useState<any>({ type: "FINANCE_LIMIT" });
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading } = useSWR<any>(
    "swr.ScfProgramApi.get",
    async () => {
      try {
        const res = await ScfProgramApi.getScf(params.id!);
        return res;
      } catch (error: any) {
        console.log("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  return (
    <PageLayout title="Хөтөлбөрийн мэдээлэл" subTitle="Манайх анкороор оролцож буй хөтөлбөрийн мэдээлэл" breadcrumb={breadcrumbs}>
      <Tabs
        value={filters.type}
        onTabChange={(e) => {
          setFilters({
            ...filters,
            type: e,
          });
        }}>
        <Tabs.List>
          <Tabs.Tab value="FINANCE_LIMIT" py="md">
            Санхүүжилтийн лимит
          </Tabs.Tab>
          <Tabs.Tab value="FINANCE_CONDITIONS" py="md">
            Санхүүжих нөхцөл
          </Tabs.Tab>
          {((data?.product?.type === "BUYER_LED" && user?.currentBusiness?.type === "BUYER") ||
            (data?.product?.type === "SUPPLIER_LED" && user?.currentBusiness?.type === "SUPPLIER")) && (
            <Tabs.Tab value="PROGRAM_BUYER" py="md">
              Хөтөлбөрийн SME
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="FINANCE_LIMIT">{!isLoading && data && filters.type === "FINANCE_LIMIT" && <FinanceLimit data={data} />}</Tabs.Panel>
        <Tabs.Panel value="FINANCE_CONDITIONS">{!isLoading && data && filters.type === "FINANCE_CONDITIONS" && <FinanceConditions data={data} />}</Tabs.Panel>
        <Tabs.Panel value="PROGRAM_BUYER">
          {!isLoading && data && filters.type === "PROGRAM_BUYER" && (
            <div>
              {user?.currentBusiness?.type === "BUYER" && user.clientType === "ANCHOR" ? <ProgramBuyerAnchor data={data} /> : <ProgramBuyer data={data} />}
            </div>
          )}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    label: "Хөтөлбөрийн мэдээлэл",
  },
];
