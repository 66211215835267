interface ErrorMessage {
  code: string;
  error: string;
  details?: { [key: string]: any };
  message: string;
}

export class HttpHandler {
  statusCode: number;
  code: string | undefined;
  error: string | undefined;
  message: string | undefined;
  details?: { [key: string]: any };
  payload: any | undefined;

  constructor(statusCode: number, errorMessage?: ErrorMessage) {
    const { code, error, message, details, ...rest } = errorMessage || {};

    this.statusCode = statusCode;
    this.code = code;
    this.error = error;
    this.message = message;
    this.details = details;
    this.payload = rest;
  }

  async handle(res: Response) {
    let data;

    try {
      try {
        data = await res.json();
      } catch (err) {
        data = await res.text();
      }
    } catch (err) {
      throw new HttpHandler(this.statusCode, {
        code: "PARSE_ERROR",
        error: "PARSE_ERROR",
        message: "Холболтын алдаа гарлаа!",
      });
    }

    switch (this.statusCode) {
      case 200:
      case 304:
        return data;
      case 400: {
        if (data.code === "JoiValidationError") {
          throw new HttpHandler(this.statusCode, {
            ...data,
            message: "Талбарууд алдаатай байна!",
            details: data.message,
          });
        }

        throw new HttpHandler(this.statusCode, data);
      }
      default:
        throw new HttpHandler(this.statusCode, data);
    }
  }
}
