import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();
const type = "finance";

export const uploadImage = async (data: any) => {
  return httpRequest.upload(`/mdi/file/${type}/upload`, data);
};

export const uploadFile = async (data: any) => {
  return httpRequest.upload(`/mdi/file/${type}/upload`, data);
};

export const get = async (id: string, name: string) => {
  return httpRequest.get(`/p/${id}/${name}`);
};
