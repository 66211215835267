import { DatePicker } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: any;
  required?: boolean;
  excludeDate?: any;
};

export function DatePickerField({ name, label, placeholder, disabled = false, icon, required = false, excludeDate }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <DatePicker
      excludeDate={excludeDate}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      inputFormat="MM-DD-YYYY"
      error={error}
      clearable={false}
      rightSection={icon}
      value={value ? new Date(value) : null}
      onChange={(e: Date) => onChange(new Date(e))}
      required={required}
    />
  );
}
