import { Checkbox, createStyles, Input } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  children?: string;
  onChange?: (e: any) => void;
};

export function CheckboxField({ name, label, disabled = false, required = false, children, onChange: onChangeProps }: Props) {
  const { value, error, onChange } = useField(name);
  const { classes } = useStyle();
  return (
    <Input.Wrapper label={label} required={required} error={error}>
      <div className={classes.row}>
        <Checkbox
          disabled={disabled}
          checked={value}
          onChange={(e) => {
            onChange(e.currentTarget.checked);
            onChangeProps && onChangeProps(e.currentTarget.checked);
          }}
          label={children}
        />
      </div>
    </Input.Wrapper>
  );
}

const useStyle = createStyles(() => ({
  row: {
    height: "35px",
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
}));
