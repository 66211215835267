import { Badge, MantineColor } from "@mantine/core";
import { ColorFormat } from "@mantine/core/lib/ColorPicker/types";

function Tag(color: MantineColor | ColorFormat, name: string) {
  if (color && color?.slice(0, 1) === "#")
    return (
      <Badge
        // style={{ border: `${color} 1px solid`, color: color }}
        size="md"
        radius="xl"
        variant="filled"
        bg={color}>
        {name || "-"}
      </Badge>
    );
  else
    return (
      <Badge color={color} size="md" radius="xl" variant="filled">
        {name || "-"}
      </Badge>
    );
}

export { Tag };
