import { ActionIcon, Anchor, Avatar, Flex, Group, Paper, Text } from "@mantine/core";
import { IconFileText } from "@tabler/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ScfRequestApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";

export const RequestSupplierLBF = ({ getInvoiceLbf }: { getInvoiceLbf: (e: any) => Promise<void> }) => {
  const { scfRequestStatus, currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    currencies,
    scfRequestStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          getInvoiceLbf(item?.id);
          break;

        default:
          break;
      }
    },
  });

  return (
    <Paper>
      <Table
        minWidth={2400}
        name="requset.fund.lbf.supplier.list"
        columns={columns}
        filters={{ financeType: "LBF" }}
        loadData={(data) => ScfRequestApi.list(data!)}
      />
    </Paper>
  );
};

const useHeader = ({
  onClick,
  scfRequestStatus,
  currencies,
}: {
  onClick: (e1: string, e2: any) => void;
  scfRequestStatus: IReference[];
  currencies: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    dataIndex: "action",
    render: (record, index) => (
      <Flex align="center">
        <ActionIcon color="" variant="light" onClick={() => onClick("details", record)}>
          <IconFileText />
        </ActionIcon>
      </Flex>
    ),
  },
  {
    title: "Хүсэлтийн #",
    dataIndex: "refCode",
    render: (record) =>
      record?.refCode ? (
        <Text w="max-content">
          # <Anchor>{record?.refCode}</Anchor>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Нэхэмжлэх",
    dataIndex: "refCode",
    render: (record) =>
      record?.refCode ? (
        <Text w="max-content">
          # <Anchor>{record?.invoice?.refCode}</Anchor>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Хүсэлт төлөв",
    dataIndex: "invoiceStatus",
    render: (record) => (
      <>
        {record?.lbfRequestStatus
          ? Tag(
              scfRequestStatus.find((item: IReference) => item.code === record?.lbfRequestStatus)?.color || "blue",
              scfRequestStatus.find((item: IReference) => item.code === record?.lbfRequestStatus)?.name || "-",
            )
          : "-"}
      </>
    ),
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    dataIndex: "amountToPay",
    render: (record) => (
      <Group position="right">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.invoice?.amountToPay ? currencyFormat(record?.invoice?.amountToPay || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: "Хүсэлтийн дүн",
    dataIndex: "requestedAmount",
    render: (record) => (
      <Group position="right">
        <Text w="max-content"> {currencyFormat(record?.requestedAmount || 0)} </Text>
      </Group>
    ),
  },
  {
    title: "Тооцсон шимтгэл",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.calculatedFeeAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Валют",
    dataIndex: "currency",
    render: (record) => <Text w="max-content">{currencies?.find((f: any) => f?.code === record?.currency)?.name || "Төгрөг"}</Text>,
  },
  {
    title: "Үлдэх хоног",
    dataIndex: "invoice?.paymentDate/nowDate",
    render: (record) => <Text w="max-content">{record?.invoice?.paymentDate ? dayjs(record?.invoice?.paymentDate).diff(new Date(), "days") : "-"}</Text>,
  },
  {
    title: "Нийлүүлэгч",
    dataIndex: "senderBusiness.partner",
    render: (record) => (
      <Flex direction="column" w={"max-content"}>
        <Text color="gray.7" fw={500}>
          {record?.supplierBusiness?.partner?.businessName || "-"}
        </Text>
        <Text fw={500} color="golomt">
          # {record?.supplierBusiness?.partner?.refCode || "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нийлүүлэгч бизнес",
    dataIndex: "senderBusiness",
    render: (record) => (
      <Flex direction="column" w={"max-content"}>
        <Text color="gray.7" fw={500}>
          {record?.supplierBusiness?.profileName || "-"}
        </Text>
        <Text fw={500} color="golomt">
          # {record?.supplierBusiness?.refCode || "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Хөтөлбөр нэр",
    dataIndex: "lbfProgram",
    render: (record) => (
      <Flex direction="column" w={"max-content"}>
        <Text color="gray.7" fw={500}>
          {record?.lbfProgram?.name || "-"}
        </Text>
        <Text fw={500} color="golomt">
          # {record?.lbfProgram?.refCode || "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Бүтээгдэхүүн",
    dataIndex: "lbfProduct",
    render: (record) => (
      <Flex direction="column" w={"max-content"}>
        <Text color="gray.7" fw={500}>
          {record?.lbfProduct?.name || "-"}
        </Text>
        <Text fw={500} color="golomt">
          # {record?.lbfProduct?.refCode || "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    dataIndex: "confirmedUser",
    render: (record) =>
      record?.invConfirmedUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.invConfirmedUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.invConfirmedUser?.lastName ? record?.invConfirmedUser?.lastName[0] + ". " : ""} {record?.invConfirmedUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Хүсэлт илгээсэн",
    dataIndex: "invoice?.receiver",
    render: (record) =>
      record?.requestedUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.requestedUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.requestedUser?.lastName ? record?.requestedUser?.lastName[0] + ". " : ""} {record?.requestedUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
];
