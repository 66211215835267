import { Badge, Button, Flex, Input, LoadingOverlay, Space, Table, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { currencyFormat } from "../../utils";
import { useState } from "react";

export const TabUseageLimit = ({
  selectedData,
  onSubmit,
  requestedAmount,
}: {
  selectedData: any;
  requestedAmount: number | undefined;
  onSubmit: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <>
        <Space h={"sm"} />
        <Input.Wrapper label="Санхүүжилт олгох дүн:">
          <Input w={350} value={currencyFormat(requestedAmount || 0)} readOnly />
        </Input.Wrapper>
        <Space h={"lg"} />
        <LoadingOverlay visible={false} />
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <th />
              <th>Баталсан лимит</th>
              <th>Боломжит лимит</th>
              <th>Лимит шаардлага</th>
              <th>Ашигласан лимит</th>
              <th>Олголт хүлээж буй</th>
              <th>Хүсэлт илгээсэн</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Text align="center" p={"xs"} size="sm" fw={600} color={"golomt"}>
                  Дүн
                </Text>
              </td>
              <td>{currencyFormat(selectedData?.businessSubLimit || 0)}</td>
              <td>{currencyFormat(selectedData?.businessAvailableAmount || 0)}</td>
              <td>
                {(requestedAmount || 0) <= selectedData?.businessAvailableAmount ? (
                  <Badge radius={4} variant="filled" color="green">
                    Хангасан
                  </Badge>
                ) : (
                  <Badge radius={4} variant="filled" color="red">
                    Хангаагүй
                  </Badge>
                )}
              </td>
              <td>{currencyFormat(selectedData?.businessUtilizedAmount || 0)}</td>
              <td>{currencyFormat(selectedData?.businessDisbursePendingAmount || 0)}</td>
              <td>{currencyFormat(selectedData?.businessPendingAmount || 0)}</td>
            </tr>
            <tr>
              <td>
                <Text align="center" p={"xs"} size="sm" fw={600} color={"golomt"}>
                  Тоо
                </Text>
              </td>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td>{"-"}</td>
              <td> {selectedData?.businessUtilizedCount || 0}</td>
              <td>{selectedData?.businessDisbursePendingCount || 0}</td>
              <td>{selectedData?.businessPendingCount || 0}</td>
            </tr>
          </tbody>
        </Table>
        <Flex mt={"lg"}>
          <Button
            loading={loading}
            disabled={(requestedAmount || 0) > selectedData?.businessAvailableLimit}
            onClick={async () => {
              setLoading(true);
              await onSubmit();
              setLoading(false);
            }}
            rightIcon={<IconCheck size={20} color="white" />}>
            Хүсэлт батлах
          </Button>
        </Flex>
      </>
    </div>
  );
};
