import { NumberInput } from "@mantine/core";
import { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  hideControls?: boolean;
  classNames?: Partial<Record<BaseSelectStylesNames, string>> | undefined;
  defaultValue?: any;
  max?: number | undefined;
  errors?: any;
  styles?: any;
  rightSection?: any;
  rightSectionWidth?: number;
  required?: boolean;
  precision?: number;
  onChangeCustom?: (e: any) => void;
  icon?: any;
  iconWidth?: number;
};

export function NumberField({
  name,
  label,
  noError = false,
  hideControls = true,
  placeholder,
  disabled = false,
  classNames,
  defaultValue,
  max,
  errors,
  styles,
  rightSectionWidth,
  rightSection,
  required = false,
  precision,
  onChangeCustom,
  icon,
  iconWidth,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <NumberInput
        type="number"
        iconWidth={iconWidth}
        icon={icon}
        precision={precision}
        rightSectionWidth={rightSectionWidth}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={errors ? errors : error}
        value={value || 0}
        hideControls={hideControls}
        onChange={(v) => {
          onChange(v);
          onChangeCustom && onChangeCustom(v);
        }}
        classNames={classNames}
        max={max}
        styles={{
          error: {
            display: noError ? (errors ? "block" : "none") : "block",
            marginBottom: errors ? -7 : 0,
          },
          wrapper: {
            marginBottom: error && noError && 0,
            marginTop: errors ? 10 : 0,
          },
          ...styles,
        }}
        rightSection={rightSection}
        required={required}
      />
    </>
  );
}
