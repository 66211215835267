import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  types: [],
  basePriceTypes: [],
  businessTypes: [],
  categoryTypes: [],
  currencies: [],
  fieldTypes: [],
  groupPriceStatus: [],
  itemImageTypes: [],
  itemStatus: [],
  numberValueLabels: [],
  perTypes: [],
  priceChangeTypes: [],
  priceEndTypes: [],
  returnTypes: [],
  unitConvertTypes: [],
  unitSpaceLabels: [],
  unitWeightLabels: [],
  specialPriceStatus: [],
  brands: [],
  manufacturers: [],
  countries: [],
  distributors: [],
  tags: [],
  categories: [],
  suppliers: [],
  itemTypes: [],
  invoiceOverdueStatus: [],
  invoiceTypes: [],
  invoicePaymentStatus: [],
  invoiceStatus: [],
  businessStatus: [],
  scfRequestTypes: [],
  scfRequestStatus: [],
  productSuppPenaltyTypes: [],
  productSuppFeeRules: [],
  programRepaymentRules: [],
  repaymentPaymentStatus: [],
  productBuyerFeeRules: [],
  productBuyerPenaltyTypes: [],
  repaymentOverDueStatus: [],
  programRegistrationStatus: [],
  programStatus: [],
  programTypes: [],
  paymentMethods: [],
  bankAccounts: [],
  fundingInstructionStatus: [],
  repaymentStatus: [],
  programParticipantStatus: [],
  networkDiscountTypes: [],
  networkPenaltyTypes: [],
  notificationTypes: [],
  reminderConnectTypes: [],
  settlementStatus: [],
  productTypes: [],
  transactionTypes: [],
  loanStatus: [],
  lbfProgramStatus: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        types,
        basePriceTypes,
        businessTypes,
        categoryTypes,
        currencies,
        invoiceOverdueStatus,
        fieldTypes,
        groupPriceStatus,
        itemImageTypes,
        itemStatus,
        numberValueLabels,
        perTypes,
        priceChangeTypes,
        priceEndTypes,
        returnTypes,
        unitConvertTypes,
        unitSpaceLabels,
        unitWeightLabels,
        specialPriceStatus,
        brands,
        manufacturers,
        countries,
        distributors,
        tags,
        categories,
        suppliers,
        invoicePaymentStatus,
        invoiceTypes,
        itemTypes,
        invoiceStatus,
        businessStatus,
        scfRequestTypes,
        scfRequestStatus,
        productSuppPenaltyTypes,
        productSuppFeeRules,
        programRepaymentRules,
        repaymentPaymentStatus,
        productBuyerFeeRules,
        productBuyerPenaltyTypes,
        repaymentOverDueStatus,
        programRegistrationStatus,
        programStatus,
        programTypes,

        paymentMethods,
        bankAccounts,
        fundingInstructionStatus,
        repaymentStatus,
        programParticipantStatus,
        networkDiscountTypes,
        networkPenaltyTypes,
        notificationTypes,
        reminderConnectTypes,
        settlementStatus,

        transactionTypes,
        productTypes,
        loanStatus,
        lbfProgramStatus,
      } = payload;

      state.types = types;
      state.basePriceTypes = basePriceTypes;
      state.businessTypes = businessTypes;
      state.categoryTypes = categoryTypes;
      state.currencies = currencies;
      state.fieldTypes = fieldTypes;
      state.groupPriceStatus = groupPriceStatus;
      state.itemImageTypes = itemImageTypes;
      state.itemStatus = itemStatus;
      state.numberValueLabels = numberValueLabels;
      state.perTypes = perTypes;
      state.priceChangeTypes = priceChangeTypes;
      state.priceEndTypes = priceEndTypes;
      state.returnTypes = returnTypes;
      state.unitConvertTypes = unitConvertTypes;
      state.unitSpaceLabels = unitSpaceLabels;
      state.unitWeightLabels = unitWeightLabels;
      state.specialPriceStatus = specialPriceStatus;
      state.invoiceOverdueStatus = invoiceOverdueStatus;
      state.brands = brands;
      state.manufacturers = manufacturers;
      state.countries = countries;
      state.invoicePaymentStatus = invoicePaymentStatus;
      state.distributors = distributors;
      state.tags = tags;
      state.categories = categories;
      state.suppliers = suppliers;
      state.itemTypes = itemTypes;
      state.invoiceTypes = invoiceTypes;
      state.invoiceStatus = invoiceStatus;
      state.businessStatus = businessStatus;
      state.scfRequestTypes = scfRequestTypes;
      state.scfRequestStatus = scfRequestStatus;
      state.productSuppPenaltyTypes = productSuppPenaltyTypes;
      state.productSuppFeeRules = productSuppFeeRules;
      state.programRepaymentRules = programRepaymentRules;
      state.repaymentPaymentStatus = repaymentPaymentStatus;
      state.productBuyerFeeRules = productBuyerFeeRules;
      state.productBuyerPenaltyTypes = productBuyerPenaltyTypes;
      state.repaymentOverDueStatus = repaymentOverDueStatus;
      state.programRegistrationStatus = programRegistrationStatus;
      state.programStatus = programStatus;
      state.programTypes = programTypes;

      state.paymentMethods = paymentMethods;
      state.bankAccounts = bankAccounts;
      state.fundingInstructionStatus = fundingInstructionStatus;
      state.repaymentStatus = repaymentStatus;
      state.programParticipantStatus = programParticipantStatus;
      state.networkDiscountTypes = networkDiscountTypes;
      state.networkPenaltyTypes = networkPenaltyTypes;
      state.notificationTypes = notificationTypes;
      state.reminderConnectTypes = reminderConnectTypes;
      state.settlementStatus = settlementStatus;
      state.productTypes = productTypes;
      state.transactionTypes = transactionTypes;
      state.loanStatus = loanStatus;

      state.lbfProgramStatus = lbfProgramStatus;
      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral } = generalSlice.actions;
