import { ActionIcon, Alert, Box, Button, Code, Drawer, Flex, Group, Modal, Paper, Space, Tabs, Text, createStyles } from "@mantine/core";
import { IconChevronsRight, IconFileInvoice, IconSend, IconShare, IconSquareArrowLeft } from "@tabler/icons";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { FinanceableApi, PinApi, ProgramApi, ScfRequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { OtpFieldInput } from "../form/otp-field-input";
import { PageLayout } from "../layout";
import { ITableRef } from "../table";
import { BuyerLBFList } from "./buyer-list";
import { LendDetailsInvoice } from "./details-invoice";
import { LendDetailsRequestBuyer } from "./details-request-buyer";
import { LendDetailsRequestSupplier } from "./details-request-supplier";
import { SupplierLBFList } from "./supplier-list";
import { getLbfProgramReduce } from "../../store/Programs";

const schema = yup.object({
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(6, "Хэт урт байна!")
    .min(6, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

export const LbfInvoiceList = () => {
  const params = useParams();
  const tableRefLbf = useRef<ITableRef>(null);
  const breadcrumbs = useBreadcrumb(params?.type);
  const [action, setAction] = useState<any>([]);
  const [tabKey, setTabKey] = useState<string>("1");
  const [selectedInv, setSelectedInv] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { theme } = useStyle();
  const dispatch = useDispatch();

  const getInvoiceLbf = async ({ id, programId }: { id: string; programId: string }) => {
    setIsLoading(true);
    try {
      let res = await FinanceableApi.getLbf(id, programId);

      setAction(["funding", res, { invId: id, lbfProgramId: programId }]);
      setTabKey("1");
    } catch (error: any) {
      console.log("error: ", error);
      error?.message && message.error(error.message);
    }
    setIsLoading(false);
  };

  const onSubmit = async (values: { requestedAmount: number }) => {
    setAction(["pin", { requestedAmount: values.requestedAmount, ...action[2] }]);
  };

  const onSubmitCreateLbf = async (data: any) => {
    console.log("action: ", action);

    setIsLoading(true);
    try {
      let res = await PinApi.check(data);
      if (res) {
        try {
          await ScfRequestApi.createLbf({ ...action[1] });
          message.success("хүсэлт амжилттай.");

          await tableRefLbf.current?.reload();
          setAction([]);
        } catch (error: any) {
          error?.message || message.error(error?.message);
        }
      }
    } catch (error: any) {
      error?.message && message.error(error?.message);
    }
    setIsLoading(false);
  };

  return (
    <Paper>
      <PageLayout
        title="Боломжит нэхэмжлэх"
        subTitle="Зээлийн шугам ашиглах хүсэлт илгээж болох боломжит нэхэмжлэхүүд"
        breadcrumb={breadcrumbs}
        extra={[
          <Group key={0}>
            <Button
              disabled={!selectedInv || isLoading}
              onClick={() => {
                getInvoiceLbf({ id: selectedInv, programId: params.id! });
              }}
              size="sm"
              key={0}>
              Санхүүжилт хүсэх
            </Button>
          </Group>,
        ]}>
        {user?.currentBusiness?.type === "BUYER" && (
          <div>
            <SupplierLBFList action={action} setAction={setAction} selectedInv={selectedInv} setSelectedInv={setSelectedInv} tableRefLbf={tableRefLbf} />
          </div>
        )}
        {user?.currentBusiness?.type === "SUPPLIER" && (
          <div>
            <BuyerLBFList action={action} setAction={setAction} selectedInv={selectedInv} setSelectedInv={setSelectedInv} tableRefLbf={tableRefLbf} />
          </div>
        )}
      </PageLayout>
      <Drawer
        withCloseButton={false}
        position="right"
        title={false}
        padding={"lg"}
        size={"100rem"}
        opened={action[0] === "funding"}
        onClose={() => setAction([])}>
        <Box>
          <Form
            initialValues={{ requestedAmount: action[1]?.amountToPay }}
            onSubmit={onSubmit}
            validationSchema={yup.object({
              requestedAmount: yup.number()?.moreThan(0, "0-с их үнэ байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
            })}>
            {({ values }) => {
              return (
                <div>
                  <Group w={"100%"} position="apart">
                    <Flex align="center" gap={"xs"}>
                      <ActionIcon color="golomt" onClick={() => setAction([])}>
                        <IconSquareArrowLeft />
                      </ActionIcon>
                      <Text fw={700}>Нэхэмжлэх</Text>
                      <Text color="golomt" fw={700}>
                        # {action[1]?.refCode}
                      </Text>
                    </Flex>
                    <Button disabled={tabKey === "1"} type="submit" loading={isLoading} rightIcon={<IconChevronsRight />}>
                      Санхүүжилт хүсэх
                    </Button>
                  </Group>
                  <Space mt={"lg"} />
                  <Paper p={"lg"} withBorder>
                    <Tabs onTabChange={(e: string) => setTabKey(e)} value={tabKey}>
                      <Tabs.List>
                        <Tabs.Tab value="1">
                          <Flex align="center" gap={"xs"}>
                            <IconFileInvoice color={tabKey === "1" ? theme.colors.golomt[6] : theme.colors.gray[7]} />
                            <Text fw={500} color={tabKey === "1" ? "golomt" : theme.colors.gray[7]}>
                              Нэхэмжлэх
                            </Text>
                          </Flex>
                        </Tabs.Tab>
                        <Tabs.Tab value="2">
                          <Flex align="center" gap={"xs"}>
                            <IconSend color={tabKey === "2" ? theme.colors.golomt[6] : theme.colors.gray[7]} />
                            <Text fw={500} color={tabKey === "2" ? "golomt" : theme.colors.gray[7]}>
                              Хүсэлт
                            </Text>
                          </Flex>
                        </Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="1">
                        <LendDetailsInvoice action={action} />
                      </Tabs.Panel>
                      <Tabs.Panel value="2">
                        {user?.currentBusiness?.type === "SUPPLIER" && (
                          <LendDetailsRequestSupplier action={action} values={{ requestedAmount: values.requestedAmount }} />
                        )}
                        {user?.currentBusiness?.type === "BUYER" && (
                          <LendDetailsRequestBuyer action={action} values={{ requestedAmount: values.requestedAmount }} />
                        )}
                      </Tabs.Panel>
                    </Tabs>
                  </Paper>
                </div>
              );
            }}
          </Form>
        </Box>
      </Drawer>

      <Modal
        title={
          <Flex align="center" gap={"sm"}>
            <IconShare size={"1.2rem"} /> <Text fw={600}>Хүсэлт илгээх</Text>
          </Flex>
        }
        opened={action[0] === "pin"}
        onClose={() => {
          setAction([]);
          dispatch(getLbfProgramReduce(null));
        }}
        centered>
        <Form
          initialValues={{
            pin: undefined,
          }}
          validationSchema={schema}
          onSubmit={onSubmitCreateLbf}>
          {() => {
            return (
              <div>
                <Alert>Та Богд банканд “Зээлийн лимит” ашиглах санхүүжилтийн хүсэлт илгээх гэж байна.</Alert>
                <Space h="lg" />
                <OtpFieldInput label="Та итгэлтэй байвал ПИН кодоо оруулж батлана уу." name="pin" size="md" required />
                <Group mt={"lg"} position="apart">
                  <Button onClick={() => setAction([])} variant="outline">
                    Болих
                  </Button>
                  <Button loading={isLoading} type="submit">
                    Батлах
                  </Button>
                </Group>
              </div>
            );
          }}
        </Form>
      </Modal>
    </Paper>
  );
};

const useStyle = createStyles({});

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Боломжит нэхэмжлэх, хөтөлбөр",
  },
  {
    label: "Зээлийн шугам",
  },
];
