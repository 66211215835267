import { Badge, Group, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { ProgramApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

export const ScfFinanceLimitList = ({ filters }: any) => {
  const { programTypes = [], programStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const status: any[] = [...programTypes, ...programStatus];

  function Tag(type: string) {
    switch (type) {
      case "ACTIVE":
        return (
          <Badge color="green" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );

      case "INACTIVE":
        return (
          <Badge color="gray" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );

      case "DRAFT":
        return (
          <Badge color="gray" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      case "REGISTERED":
        return (
          <Badge color="teal" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      case "REVIEWED":
        return (
          <Badge color="blue" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      case "REJECTED":
        return (
          <Badge color="red" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      case "CLOSED":
        return (
          <Badge color="gray" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      case "CANCELLED":
        return (
          <Badge color="red" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );

      case "BASIC_INFO_REVIEWED":
        return (
          <Badge color="grape" size="md" radius="sm" variant="outline">
            {status.find((item: any) => item.code === type)?.name || type}
          </Badge>
        );
      default:
        return (
          <Badge color="red" size="md" radius="sm" variant="outline">
            {type}
          </Badge>
        );
    }
  }

  const columns = useHeader({
    Tag,
    currencyFormat,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          break;
        }
        default:
      }
    },
  });

  return <Table name="scf.finance.limit.list" columns={columns} filters={filters} loadData={(data) => ProgramApi.limit_usage(data!)} />;
};

const useHeader = ({ onClick, Tag, currencyFormat }: { onClick: (key: string, record: any) => void; Tag: any; currencyFormat: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөр #",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text color="golomt" w={"max-content"}>
          # {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <Text w={"max-content"}>{record?.name || "-"}</Text>;
    },
  },
  {
    title: "Хөтөлбөрийн статус",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return <Text w={"max-content"}>{Tag(record.programStatus)}</Text>;
    },
  },
  {
    title: "Хөтөлбөрийн лимит",
    sorter: true,
    dataIndex: "programLimit",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.subLimit || "0")}</Group>;
    },
  },
  {
    title: "Боломжит лимит",
    sorter: true,
    dataIndex: "availableAmount",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.availableAmount || "0")}</Group>;
    },
  },
  {
    title: "Ашигласан лимит",
    sorter: true,
    dataIndex: "utilizedAmount",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.utilizedAmount || "0")}</Group>;
    },
  },
  {
    title: "Олголт хүлээж буй",
    sorter: true,
    dataIndex: "toDisburseAmount",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.disbursePendingAmount || "0")}</Group>;
    },
  },
  {
    title: "Хүсэлт хүлээн авсан",
    sorter: true,
    dataIndex: "disbursedAmount",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.pendingAmount || "0")}</Group>;
    },
  },
  {
    title: "Корпорэйт анкор",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return <Text w={"max-content"}>{record?.anchor?.partner?.businessName || "-"}</Text>;
    },
  },
  {
    title: "Анкор бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <Text w={"max-content"}>{record?.anchor?.profileName || "-"}</Text>;
    },
  },
  {
    title: "Анкор роль",
    sorter: true,
    dataIndex: "role",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          {record?.anchor?.role === "BUYER" ? (
            <Badge size="md" radius="sm" variant="outline">
              Худалдан авагч
            </Badge>
          ) : (
            <Badge size="md" radius="sm" variant="outline">
              Нийлүүлэгч
            </Badge>
          )}
        </Text>
      );
    },
  },
];
