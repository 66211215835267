import { Anchor, Avatar, Flex, Group, Paper, Text } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RepaymentApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import { Tag } from "./../../components/tags";

export function PaymentHistory({ action }: { action: any }) {
  const {
    currencies,
    productSuppFeeRules,
    repaymentStatus = [],
    programRepaymentRules = [],
    repaymentPaymentStatus,
    repaymentOverDueStatus,
    paymentMethods,
    transactionTypes,
  } = useSelector((state: { general: IGeneral }) => state.general);

  const [filters, setFilters] = useState({ repaymentId: action[1]?.id });

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail":
          break;

        default:
          break;
      }
    },
    Tag,
    repaymentStatus,
    currencies,
    productSuppFeeRules,
    programRepaymentRules,
    repaymentPaymentStatus,
    repaymentOverDueStatus,
    paymentMethods,
    transactionTypes,
  });

  return (
    <Paper>
      <Text my={"md"} fw={600}>
        Эргэн төлөлтийн гүйлгээний түүх
      </Text>
      {action[1]?.id && (
        <Table name="payment.history.list" columns={columns} filters={filters} minWidth={2400} loadData={(data) => RepaymentApi.history(data!)} />
      )}
    </Paper>
  );
}

const useHeader = ({
  onClick,
  programRepaymentRules,
  repaymentPaymentStatus,
  repaymentOverDueStatus,
  repaymentStatus,
  Tag,
  paymentMethods,
  transactionTypes,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  productSuppFeeRules: any;
  programRepaymentRules: any;
  repaymentPaymentStatus: IReference[];
  repaymentOverDueStatus: any;
  repaymentStatus: IReference[];
  Tag: any;
  paymentMethods: IReference[];
  transactionTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Огноо, цаг",
    render: (record) => (
      <Text color="dimmed" w="max-content">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => <Anchor># {record?.trxRefCode || "-"}</Anchor>,
  },
  {
    title: "Гүйлгээний лавлах",
    render: (record) => <Text w={"max-content"}>{record?.trxDesc || "-"}</Text>,
  },
  {
    title: "Нэхэмжлэх - эхний үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.firstAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Гүйлгээ - Төлбөрийн дүн",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.trxAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Нэхэмжлэх - эцсийн үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text w="max-content" ta="right">
          {currencyFormat(record?.lastAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Гүйлгээний утга",
    render: (record) => <Text w="max-content">{record?.trxDesc || "-"}</Text>,
  },
  {
    title: "Төлсөн дансны дугаар",
    render: (record) => <Text w="max-content">{record?.creditAcc?.number || "-"}</Text>,
  },
  {
    title: "Төлсөн дансны нэр",
    render: (record) => <Text w="max-content">{record?.creditAcc?.name || "-"}</Text>,
  },
  {
    title: "Хүлээн авсан дансны дугаар",
    render: (record) => <Text w="max-content">{record?.debitAcc?.number || "-"}</Text>,
  },
  {
    title: "Хүлээн авсан дансны нэр",
    render: (record) =>
      record?.debitAcc ? (
        <Text w="max-content">
          {record?.debitAcc?.name || "-"}{" "}
          <Text component="span" color="gray">
            ( {record?.debitAcc?.shortName || "-"} )
          </Text>{" "}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    render: (record) =>
      Tag(
        paymentMethods?.find((item: IReference) => item?.code === record?.paymentMethod)?.color || "",
        paymentMethods?.find((item: IReference) => item?.code === record?.paymentMethod)?.name || "-",
      ),
  },
  {
    title: "Төлбөрийн төрөл",
    render: (record) =>
      Tag(
        transactionTypes?.find((item: IReference) => item?.code === record?.paymentType)?.color || "",
        transactionTypes?.find((item: IReference) => item?.code === record?.paymentType)?.name || record?.paymentType || "-",
      ),
  },
  {
    title: "Төлбөрийн төлөв",
    render: (record) =>
      Tag(
        repaymentStatus.find((item: IReference) => item.code === record?.repaymentStatus)?.color || "indigo",
        repaymentStatus.find((item: IReference) => item.code === record?.repaymentStatus)?.name || record?.repaymentStatus || "",
      ),
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    render: (record) =>
      Tag(
        repaymentOverDueStatus?.find((item: IReference) => item?.code === record?.overdueStatus)?.color || "",
        repaymentOverDueStatus?.find((item: IReference) => item?.code === record?.overdueStatus)?.name || record?.overdueStatus || "-",
      ),
  },
  {
    title: "Төлбөр төлсөн",
    render: (record) => (
      <Flex align="center" gap={"xs"} w={"max-content"}>
        <Avatar src={record.actionUser.avatar} />
        <div>
          <Text>
            {record?.actionUser?.lastName ? record?.actionUser?.lastName[0] + ". " : ""}
            {record?.actionUser?.firstName || "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
];
