import { ActionIcon, Button, Drawer, Group } from "@mantine/core";
import { IconFilter } from "@tabler/icons";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { DrawerFormBuyer } from "../../components/posible-buyer-led/drawer-form-buyer";
import { ITableRef } from "../../components/table";
import { InvoicePossibleBuyer } from "./buyer-list";

export const InvoicePossibleBuyerList = () => {
  const params = useParams();
  const [selectedInvs, setSelectedInvs] = useState<any[]>([]);
  const [hasFilter, setHasFilter] = useState<boolean>(true);
  const tableRef = useRef<ITableRef>(null);
  const [action, setAction] = useState<any>([]);
  const breadcrumbs = useBreadcrumb(params?.type);

  const reloadTable = async () => {
    tableRef.current?.reload();
    setSelectedInvs([]);
  };

  useEffect(() => {
    if (!action[0]) reloadTable();
  }, [action]);

  return (
    <PageLayout
      title="Боломжит нэхэмжлэх"
      subTitle="Санхүүжих боломжтой нэхэмжлэхийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <Button
            disabled={selectedInvs.length === 0}
            onClick={() => {
              setAction(["buyer_finance", selectedInvs[0], params.id]);
            }}
            size="sm"
            key={0}>
            Санхүүжилт хүсэх
          </Button>
          <ActionIcon
            color="colomt"
            onClick={() => {
              setHasFilter(!hasFilter);
            }}
            variant={!hasFilter ? "light" : "filled"}
            size={36}>
            <IconFilter />
          </ActionIcon>
        </Group>,
      ]}>
      <InvoicePossibleBuyer selectedInvs={selectedInvs} setSelectedInvs={setSelectedInvs} tableRef={tableRef} />
      <Drawer
        styles={{ drawer: { overflowY: "auto" } }}
        zIndex={100}
        opened={action[0] === "buyer_finance"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        padding="xl"
        size="80rem">
        {action[0] === "buyer_finance" && <DrawerFormBuyer action={action} setAction={setAction} reloadTable={reloadTable} />}
      </Drawer>
    </PageLayout>
  );
};

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Боломжит нэхэмжлэх, хөтөлбөр",
  },
  {
    label: "Нэхэмжлэх барьцаалах",
  },
];
