import { ActionIcon, Badge, Divider, Flex, Group, Loader, Pagination, Paper, Space, Text, createStyles } from "@mantine/core";
import { IconMailForward, IconSend } from "@tabler/icons";
import { useState } from "react";
import useSWR from "swr";
import { RepaymentApi } from "../../apis";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";

export function EmailNotify({ action }: { action: any }) {
  const { classes } = useStyle();
  const [offset, setOffset] = useState({ page: 1, limit: 10 });
  const [filter, setFilter] = useState({
    isEmail: true,
    repaymentId: action[1]?.id,
  });

  const { data, isLoading, mutate } = useSWR<any>(
    `swr.listsss.${JSON.stringify(filter)}.${JSON.stringify(offset)}`,
    async () => {
      try {
        console.log({ filter: { ...filter, isEmail: "true" }, offset: offset });
        let res = await RepaymentApi.reminder_history({ filter: { ...filter, isEmail: "true" }, offset: offset });
        return res;
      } catch (error: any) {
        message.error(error?.message);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const iconRender = (code: any) => {
    return <IconMailForward />;
  };

  console.log("data: ", data);

  return (
    <Paper>
      <Text my={"md"} fw={600}>
        Өнөөдөр
      </Text>
      {!isLoading && data?.rows?.length > 0 ? (
        data?.rows
          // ?.filter((item: any) => item.connectType === "EMAIL")
          ?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <Flex gap={8}>
                  <ActionIcon className={classes.height} color={item.color}>
                    {iconRender(item.connectType)}
                  </ActionIcon>
                  <Paper w="100%">
                    <Group className={classes.height} align="center" position="apart" w="100%">
                      <Text color={item.color} fz={"lg"} fw={600}>
                        {item?.email || "-"}
                      </Text>
                      <Text color={item.color} fz={"xs"} fw={600}>
                        {item?.type}
                      </Text>
                    </Group>
                    <Flex w="100%" align="end" wrap="nowrap" gap={8} justify="space-between">
                      <Text fz={"sm"}>{item.clientText}</Text>
                      <Flex direction="column" gap={5}>
                        <Text color="dimmed" fz={"sm"} align="end" w={"max-content"}>
                          {dateTimeFormat(item.createdAt)}
                        </Text>
                        <Badge variant="light">Илгээсэн</Badge>
                      </Flex>
                    </Flex>
                  </Paper>
                </Flex>
                <Divider mt={"sm"} variant="dashed" />
              </div>
            );
          })
      ) : (
        <Flex h={"xl"} p={"xl"} align="center" justify="center">
          <Text color="gray">Мэдээлэл байхгүй.</Text>
        </Flex>
      )}
      <Space h={"md"} />
      <Group position="right">
        <Pagination
          size="sm"
          page={offset.page}
          onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
          total={(data?.count || 0) / offset.limit + ((data?.count || 0) % offset.limit > 0 ? 1 : 0)}
        />
      </Group>
      {isLoading && <Loader />}
    </Paper>
  );
}

const datas = [
  {
    title: "To: [Finance_UserEmail]",
    description: "Mail subject name",
    type: "Хугацаа хэтрэлт сануулах",
    icon: <IconSend />,
    color: "violet.9",
  },
  {
    title: "To: [Finance_UserEmail]",
    description: "Mail subject name",
    type: "Хугацаа хэтрэлт сануулах",
    icon: <IconSend />,
    color: "violet.9",
  },
  {
    title: "To: [Finance_UserEmail]",
    description: "Mail subject name",
    type: "Хугацаа хэтрэлт сануулах",
    icon: <IconMailForward />,
    color: "violet.9",
  },
  {
    title: "To: [Finance_UserEmail]",
    description: "Mail subject name",
    type: "Хугацаа хэтрэлт сануулах",
    icon: <IconMailForward />,
    color: "violet.9",
  },
];

const useStyle = createStyles(() => ({
  height: {
    height: "50px",
  },
}));
