import { Avatar, Checkbox, Flex, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FinanceableApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const InvoicePossibleBuyer = ({
  selectedInvs = [],
  setSelectedInvs,
  tableRef,
}: {
  selectedInvs: any[];
  setSelectedInvs: (e: any) => void;
  tableRef: any;
}) => {
  const { currencies, invoicePaymentStatus, invoiceStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const params = useParams();

  const columns = useHeader({
    currencyFormat,
    selectedInvs,
    onClick: (key, record) => {
      switch (key) {
        case "detail":
          break;
        case "select":
          setSelectedInvs([record]);
          break;
        case "remove":
          setSelectedInvs([]);
          break;
        default:
          break;
      }
    },
    invoicePaymentStatus,
    currencies,
    invoiceStatus,
  });

  return (
    <Paper>
      <Table
        ref={tableRef}
        loadData={(data) => FinanceableApi.list(data!)}
        name="product.BUYER_LED.list"
        columns={columns}
        filters={{
          programId: params.id!,
          financeType: "SCF",
        }}
        minWidth={2400}
      />
    </Paper>
  );
};

const useHeader = ({
  onClick,
  invoicePaymentStatus,
  currencies,
  invoiceStatus,
  currencyFormat,
  selectedInvs = [],
}: {
  onClick: (key: string, record: any) => void;
  invoicePaymentStatus: IReference[];
  currencies: IReference[];
  invoiceStatus: IReference[];
  currencyFormat: any;
  selectedInvs: any[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Сонгох",
    render: (record) => (
      <div>
        <Checkbox
          checked={selectedInvs?.some((item: any) => item.id === record.id)}
          onChange={() => {
            if (!selectedInvs?.some((item: any) => item.id === record.id)) onClick("select", record);
            else onClick("remove", record);
          }}
        />
      </div>
    ),
  },
  {
    title: "Нэхэмжлэх #",
    render: (record) => (
      <Flex direction="column">
        <Text color="golomt" fw={500} w="max-content">
          # {record?.refCode || "-"}
        </Text>
        <Text fw={500} color="dimmed" w="max-content">
          {dayjs(record?.createdAt).format("YYYY-MM-DD HH:mm")}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Худалдан авагч бизнес",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Худалдан авагч партнер",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Нийлүүлэгч",
    render: (record) =>
      record?.type === "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Баталсан дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text fw={500} color="dimmed" w="max-content">
          {currencyFormat(record?.confirmedAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлсөн дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.paidAmount ? currencyFormat(record?.paidAmount || 0) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Валют",
    render: (record) => (
      <Text w="max-content" color="gray.7">
        {currencies?.find((f: any) => f?.code === record?.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Үлдэгдэл",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.amountToPay ? currencyFormat(record?.amountToPay || 0) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлбөр статус",
    render: (record) => (
      <>
        {Tag(
          invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.color || "blue",
          invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.name || "-",
        )}
      </>
    ),
  },
  {
    title: "Хугацаа",
    render: (record) => (
      <Text fw={500} color="dimmed" w="max-content">
        {record?.remainingDays}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан огноо",
    render: (record) => (
      <Text fw={500} color="dimmed" w="max-content">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    render: (record) => (
      <Flex gap="xs" align="center">
        <Avatar radius={100} src={record?.confirmedUser?.avatar} />
        <Flex direction="column" gap="xs">
          <Text fw={500} color="golomt">
            {record?.confirmedUser?.lastName ? record?.confirmedUser?.lastName[0] + ". " : ""} {record?.confirmedUser?.firstName || "-"}
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх статус",
    render: (record) => (
      <>
        {record?.invoiceStatus
          ? Tag(
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.color || "blue",
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.name || "-",
            )
          : "-"}
      </>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    render: (record) =>
      record?.senderUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.senderUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.senderUser?.lastName ? record?.senderUser?.lastName[0] + ". " : ""} {record?.senderUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Борлуулалтын захиалга",
    render: (record) =>
      record?.salesOrder ? (
        <Text fw={500} color="golomt">
          # {record?.salesOrder?.refCode || "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Худалдан авалтын захиалга",
    render: (record) =>
      record?.purchaseOrder ? (
        <Text fw={500} color="golomt">
          # {record?.purchaseOrder?.refCode || "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  // {
  //   title: "Үйлдэл",
  //   render: (record) => {
  //     return (
  //       <Tooltip label="Дэлгэрэнгүй">
  //         <ActionIcon variant="filled" onClick={() => onClick("detail", record)} color="golomt">
  //           <IconInfoSquare />
  //         </ActionIcon>
  //       </Tooltip>
  //     );
  //   },
  // },
];
