import { Paper, Space, Tabs, Text } from "@mantine/core";
import { useState } from "react";
import { PageLayout } from "../../components/layout";
import { LbfPossibleProgramList } from "./lbf-program-list";
import { ScfPossibleProgramList } from "./scf-program-list";

export const PossibleProgramList = () => {
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = useState<string>("SCF");

  return (
    <PageLayout title="Боломжит нэхэмжлэх, хөтөлбөр" subTitle="Санхүүжилт авах боломжтой нэхэмжлэх бүхий хөтөлбөрүүд" breadcrumb={breadcrumbs}>
      <Paper p={"md"} bg={"golomt.0"}>
        <Text color="golomt" fw={700} size={"sm"}>
          Та эхлээд хөтөлбөрөө сонгоно уу!
        </Text>
      </Paper>
      <Space h={"xs"} />
      <Tabs
        value={tabKey}
        onTabChange={(e: string) => {
          setTabKey(e);
        }}>
        <Tabs.List>
          <Tabs.Tab value="SCF" py="md">
            Нэхэмжлэх барьцаалах
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="LBF">{tabKey === "LBF" && <LbfPossibleProgramList />}</Tabs.Panel>
        <Tabs.Panel value="SCF">{tabKey === "SCF" && <ScfPossibleProgramList />}</Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Боломжит нэхэмжлэх, хөтөлбөр",
  },
];
