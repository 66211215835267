import { Badge, Divider, Flex, Grid, Input, Loader, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { RepaymentApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "../tags";

export function RepaymentDetail({ id, setSelectedData }: { id: string; setSelectedData?: any }) {
  const { programRepaymentRules, productSuppPenaltyTypes, repaymentPaymentStatus, repaymentOverDueStatus, repaymentStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const { data, isLoading } = useSWR<any>(
    `swr.api.repayment.${id}`,
    async () => {
      if (id) {
        try {
          const res = await RepaymentApi.get(id);

          return res;
        } catch (error: any) {
          message.error(error?.message);
        }
      } else return null;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  if (isLoading)
    return (
      <Flex justify="center" p={"xl"} h="xxl" align="center">
        <Loader size={"sm"} />
      </Flex>
    );
  else
    return (
      <Grid gutter={"lg"}>
        <Grid.Col span={12}>
          <Divider
            mt={"lg"}
            label={
              <Text color="golomt" fw={600} size={"md"}>
                Эргэн төлөлт хариуцагч тал
              </Text>
            }
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр" required>
            <Flex gap={8}>
              <Text size={"sm"}>{data?.payerBusiness?.partner?.businessName || "-"},</Text>
              <Text color="golomt" size={"sm"}>
                # {data?.payerBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.payerBusiness?.regNumber || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгч бизнес" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.payerBusiness?.profileName || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх код" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.invoice?.refCode || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөх сүүлийн хугацаа" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.invDueDate ? dateTimeFormat(data?.invDueDate) : "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүлээж буй хугацаа" required>
            <Flex align={"center"}>
              {data?.remainingDays >= 0 ? (
                <Text color="golomt" size={"sm"} fw={500}>
                  {data?.remainingDays} хоног
                </Text>
              ) : (
                <Text fw={500} color="red" size={"sm"}>
                  {Math.abs(data?.remainingDays)} хоног хэтэрсэн байна.
                </Text>
              )}
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Зогсох огноо" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.restrictDate ? dateTimeFormat(data?.restrictDate) : "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах эсэх" required>
            <Flex align={"center"}>
              <Text size={"sm"}>
                {data?.recourseTerm ? (
                  <Badge color="green" size="sm">
                    Тийм
                  </Badge>
                ) : (
                  <Badge color="red" size="sm">
                    Үгүй
                  </Badge>
                )}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөх дүрэм" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{programRepaymentRules?.find((mk: any) => mk?.code === data?.repaymentRule)?.name || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөх дүн" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.repaymentAmount ? currencyFormat(data?.repaymentAmount) : "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлсөн дүн" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.paidAmount ? currencyFormat(data?.paidAmount) : "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн үлдэгдэл" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{currencyFormat(data?.amountToPay)}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Алданги дүрэм" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{productSuppPenaltyTypes.find((mk: any) => mk?.code === data?.penaltyType)?.name || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Алданги (хувь)" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.penaltyPercent} %</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}></Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөлтийн нэхэмжлэх төлөв" required>
            <Flex align={"center"}>
              {Tag(
                repaymentStatus.find((item: IReference) => item.code === data?.repaymentStatus)?.color || "blue",
                repaymentStatus.find((item: IReference) => item.code === data?.repaymentStatus)?.name || "-",
              )}
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн төлөв" required>
            <Flex align={"center"}>
              <Text size={"sm"}>
                {Tag(
                  repaymentPaymentStatus.find((item: IReference) => item.code === data?.paymentStatus)?.color || "blue",
                  repaymentPaymentStatus.find((item: IReference) => item.code === data?.paymentStatus)?.name || "-",
                )}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв" required>
            <Flex align={"center"}>
              <Text size={"sm"}>
                {Tag(
                  repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.color || "blue",
                  repaymentOverDueStatus.find((item: IReference) => item.code === data?.overdueStatus)?.name || "-",
                )}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider
            label={
              <Text fw={600} size={"md"}>
                Дансны мэдээлэл
              </Text>
            }
          />
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөлт авах банк" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.receiverAcc?.bankName || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөлт авах данс" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.receiverAcc?.number || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөлт авах дансны нэр" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.receiverAcc?.name || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөт татах банк" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.payerAcc?.bankName || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөт татах данс" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.payerAcc?.number || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Эргэн төлөт татах дансы нэр" required>
            <Flex align={"center"}>
              <Text size={"sm"}>{data?.payerAcc?.name || "-"}</Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    );
}
