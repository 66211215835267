import { Breadcrumbs } from '@mantine/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  data: { label: string, to?: string }[];
}

export function Breadcrumb ({ data }: Props) {
  return (
    <Breadcrumbs separator="/">
      {data.map((item, index) => (
        <Fragment key={index}>
          {item.to ? (
            <Link to={item.to}>
              {item.label}
            </Link> 
          ) : (
            <span>{item.label}</span>
          )}
        </Fragment>
      ))}
    </Breadcrumbs>
  );
}