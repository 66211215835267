import { ActionIcon, Anchor, Avatar, Group, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconPhoto } from "@tabler/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ScfProgramApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const BuyerSubcontractList = () => {
  const navigate = useNavigate();
  const { programStatus, lbfProgramStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    lbfProgramStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          navigate(`/finance-program/${item.id}`);
          break;

        default:
          break;
      }
    },
  });

  return (
    <Table minWidth={2400} name="requests.list.buyer.led" columns={columns} filters={{ financeType: "LBF" }} loadData={(data) => ScfProgramApi.list(data!)} />
  );
};

const useHeader = ({ onClick, lbfProgramStatus }: { onClick: (e1: string, e2: any) => void; lbfProgramStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
            <IconFileText />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.name || "-"}</Text>
          <Anchor># {record?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.lbfProduct?.name || "-"}</Text> <Anchor># {record?.lbfProduct?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    render: (record) => {
      return Tag(
        lbfProgramStatus.find((item: IReference) => item.code === record.lbfProgramStatus)?.color || "",
        lbfProgramStatus.find((item: IReference) => item.code === record.lbfProgramStatus)?.name || "-",
      );
    },
  },
  {
    title: "Анкор партнер",
    render: (record) => {
      return record?.anchor?.partnerName ? (
        <Group w={"max-content"}>
          <Avatar src={record.anchor.logo} size={32}>
            <IconPhoto />
          </Avatar>
          <Text>{record?.anchor?.partnerName || "-"}</Text>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Анкор бизнес",
    render: (record) => {
      return record?.anchor?.refCode ? (
        <Group w={"max-content"}>
          <div>
            <Text>{record?.anchor?.profileName || "-"}</Text> <Anchor># {record?.anchor?.refCode || "-"}</Anchor>
          </div>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Баталсан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record?.lbfProgramLimit || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "SME тоо",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {record?.smeCount || "-"}
        </Text>
      </Group>
    ),
  },
];
