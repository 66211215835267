import { Button, Code, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "../tags";
import dayjs from "dayjs";

export const TabInvoice = ({ data }: { data: any }) => {
  const { invoiceStatus, invoicePaymentStatus, invoiceOverdueStatus } = useSelector((state: { general: IGeneral }) => state.general);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Divider
            label={
              <Text c="golomt" fw={600} size={"md"}>
                Ерөнхий мэдээлэл
              </Text>
            }
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн код:">
            <Text fz="sm" fw={500}>
              {data?.refCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх илгээсэн:">
            <Text fz="sm" fw={500}>
              {data?.senderUser?.lastName ? data?.senderUser?.lastName[0] + ". " : ""}
              {data?.senderUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх баталсан:">
            <Text fz="sm" fw={500}>
              {data?.receiverFinUser?.lastName ? data?.receiverFinUser?.lastName[0] + ". " : ""} {data?.receiverFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Баталсан дүн:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.confirmedAmount || 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлсөн дүн:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.paidAmount || 0) || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.amountToPay || 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.color || "gray",
                invoiceStatus?.find((item: IReference) => item.code === data?.invoiceStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.color || "gray",
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.paymentStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.color || "gray",
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.overdueStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн нөхцөл:">
            <Text fz="sm" fw={500}>
              {data?.paymentTermDesc}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
            <Text fz="sm" fw={500}>
              {data?.paymentDate ? dateTimeFormat(data?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн баримт:">
            <div>
              <Button disabled variant="light" size="xs" leftIcon={<IconDownload size={18} color="#15AABF" />}>
                INV_3222.PDF
              </Button>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider
            label={
              <Text color="golomt" fw={600} size={"md"}>
                Нийлүүлэгч талын мэдээлэл
              </Text>
            }
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр:">
            <Flex gap={8}>
              <Text fz="sm" fw={500}>
                {data?.senderBusiness?.partner?.businessName || "-"}
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.senderBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар:">
            <Text fz="sm" fw={500}>
              {data?.senderBusiness?.regNumber || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгч бизнес:">
            <Flex gap={8}>
              <Text fz="sm" fw={500}>
                {data?.senderBusiness?.profileName || "-"}
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.senderBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгчийн данс:">
            <Text fz="sm" fw={500}>
              {data?.senderAcc?.name || "-"}, {data?.senderAcc?.number || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Борлуулалтын захиалга:">
            <Text fz="sm" fw={500}>
              {data?.salesOrder?.refCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан:">
            <Text fz="sm" fw={500}>
              {data?.senderFinUser?.lastName ? data?.senderFinUser?.lastName[0] + ". " : ""} {data?.senderFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider
            label={
              <Text fw={600} size={16} color="golomt">
                Худалдан авагч талын мэдээлэл
              </Text>
            }
          />
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр:">
            <Flex gap={8}>
              <Text fz="sm" fw={500}>
                {data?.receiverBusiness?.partner?.businessName || "-"}
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.receiverBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар:">
            <Text fz="sm" fw={500}>
              {data?.receiverBusiness?.regNumber || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагч бизнес:">
            <Flex align={"center"} gap={8}>
              <Text fz="sm" fw={500}>
                {data?.receiverBusiness?.profileName || "-"},
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.receiverBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагч данс:">
            <Text fz="sm" fw={500}>
              {data?.receiverAcc?.name || "-"}, {data?.receiverAcc?.number || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авалтын захиалга:">
            <Text fz="sm" fw={500}>
              {data?.purchaseOrder?.refCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан:">
            <Text fz="sm" fw={500}>
              {data?.receiverFinUser?.lastName ? data?.receiverFinUser?.lastName[0] + ". " : ""} {data?.receiverFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
