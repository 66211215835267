import { Anchor, Badge, Group, Paper, Text, createStyles } from "@mantine/core";
import { IconCaretRight } from "@tabler/icons";
import { useState } from "react";
import { ColumnType, Table } from "../../components/table";
import { currencyFormat } from "../../utils";
import { useScreenDetector } from "../screen-detector";

export const FundedInvoiceList = ({ data }: { data: any }) => {
  const { classes } = useStyles();
  const { isMobile } = useScreenDetector();

  const [filters] = useState({
    query: "",
  });

  const columns = useHeader();

  return (
    <div>
      <Paper withBorder py={"xs"}>
        <Group position="apart">
          <Group align="center" noWrap>
            <div className={classes.grdnt}></div>
            <Text fw={600}>Санхүүжсэн нэхэмжлэх</Text>
          </Group>
          {!isMobile ? (
            <Anchor size={"xs"} px={"xs"}>
              Бүгдийг харах
            </Anchor>
          ) : (
            <Anchor px={"xs"}>
              <IconCaretRight />
            </Anchor>
          )}
        </Group>
        <Paper px={"lg"} style={{ overflow: "auto" }}>
          <Table
            pagination={false}
            name="highest.balance.list"
            columns={columns}
            filters={{ ...filters }}
            // loadData={(data) => InvoiceApi.close(data!)}
            dataSource={data?.financed}
          />
        </Paper>
      </Paper>
    </div>
  );
};

const useHeader = (): ColumnType<any>[] => [
  {
    title: "Төлөлт хэтэрсэн",
    render: (record) => {
      return (
        <Group w={"max-content"} noWrap>
          <Badge variant="filled" bg={record.color}>
            {record.name}
          </Badge>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх тоо",
    render: (record) => <Text w="max-content">{record?.invoiceCount || 0} ш</Text>,
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text>{currencyFormat(record?.amountToPay || 0)}</Text>
      </Group>
    ),
  },
  {
    title: "Бизнес тоо",
    render: (record) => <Group>{record?.businessCount || "-"}</Group>,
  },
  {
    title: "Дундаж үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text>{currencyFormat(record?.averageAmount || 0)}</Text>
      </Group>
    ),
  },
];

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.fn.linearGradient(45, theme.colors.golomt[3], theme.colors.golomt[6]),
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
