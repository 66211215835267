import { Button, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React, { useState } from "react";
import * as yup from "yup";
import { PinApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { OtpFieldInput } from "../form/otp-field-input";

const schema = yup.object({
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(6, "Хэт урт байна!")
    .min(6, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

type IForm = {
  pin: string | undefined;
};

export function ApplyFundingPinForm({ onClose, submit, action }: { onClose: () => void; submit: (e: any) => void; action: any[] }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data] = React.useState<IForm>({
    pin: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      const res = await PinApi.check({
        pin: `${values.pin}`,
      });

      if (res) {
        submit(action[1]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid>
            <LoadingOverlay visible={loading} />
            <Grid.Col span={12}>
              <Text align="center" color="#2a3536">
                Та SCF хүсэлт илгээх гэж байна:
              </Text>
              <Text align="center" color="#2a3536">
                Ta төлбөрийн мэдээллээ үнэн зөвийг баталгаажуулсан бол{" "}
                <Text color="dark" fw={600} span>
                  ПИН{" "}
                </Text>
                кодоо хийж батлана уу
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="center">
                <OtpFieldInput name="pin" />
              </Flex>
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="space-between">
                <Button onClick={() => onClose()} variant="light" color="gray">
                  Болих
                </Button>
                <Button type="submit" variant="filled">
                  Батлах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
