import { ActionIcon, Alert, Anchor, Avatar, Button, Divider, Flex, Grid, Group, LoadingOverlay, Switch, Text, Tooltip } from "@mantine/core";
import { IconAlertCircle, IconCircleMinus, IconFileText } from "@tabler/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";

const schema = yup.object({
  debtLimitAmount: yup.number().typeError("Зөв дүн оруулна уу!").optional().nullable(),
  debtLimitStartDate: yup.string().optional().nullable(),
  debtLimitEndDate: yup.string().optional().nullable(),
  earlyDiscount: yup.boolean(),
  discountPercent: yup
    .number()
    .when("earlyDiscount", {
      is: true,
      then: yup.number().moreThan(0, "0%-с их утга оруулна уу!").max(100, "Ихдээ 100% байна.").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
    })
    .nullable()
    .optional(),
  discountType: yup
    .string()
    .when("earlyDiscount", {
      is: true,
      then: yup.string().required("Заавал бөглөнө!"),
    })
    .optional(),
  countPenalty: yup.boolean(),
  penaltyType: yup
    .string()
    .when("countPenalty", {
      is: true,
      then: yup.string().required("Заавал бөглөнө!"),
    })
    .nullable(),
  penaltyPercent: yup
    .number()
    .when("countPenalty", {
      is: true,
      then: yup.number().moreThan(0, "0%-с их утга оруулна уу!").max(100, "Ихдээ 100% байна.").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
    })
    .optional(),
  weekAvgDebtAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
  halfMonthAvgDebtAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
  monthAvgDebtAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
  monthAvgPurchaseAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
  monthAvgRefundAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
  monthAvgBidAmount: yup.number().typeError("Зөв дүн оруулна уу!").required("Заавал бөглөнө!").nullable(),
});

type IForm = {
  businessIds: string[];
  debtLimitAmount: number | undefined;
  debtLimitStartDate: string | undefined;
  debtLimitEndDate: string | undefined;
  earlyDiscount: boolean;
  discountType: string | undefined;
  discountPercent: number | undefined;
  countPenalty: boolean;
  penaltyType: string | undefined;
  penaltyPercent: number | undefined;
  weekAvgDebtAmount: number | undefined;
  halfMonthAvgDebtAmount: number | undefined;
  monthAvgDebtAmount: number | undefined;
  monthAvgPurchaseAmount: number | undefined;
  monthAvgRefundAmount: number | undefined;
  monthAvgBidAmount: number | undefined;
};

export function LimitSetForm({
  reload,
  action,
  setAction,
  selected,
  setSelected,
}: {
  setAction: (e: any[]) => void;
  reload: () => void;
  action: any;
  setSelected: any;
  selected: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const { programParticipantStatus, networkPenaltyTypes, networkDiscountTypes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IForm>({
    businessIds: [],
    debtLimitAmount: undefined,
    debtLimitStartDate: undefined,
    debtLimitEndDate: undefined,
    earlyDiscount: false,
    discountType: undefined,
    discountPercent: undefined,
    countPenalty: false,
    penaltyType: undefined,
    penaltyPercent: undefined,
    weekAvgDebtAmount: undefined,
    halfMonthAvgDebtAmount: undefined,
    monthAvgDebtAmount: undefined,
    monthAvgPurchaseAmount: undefined,
    monthAvgRefundAmount: undefined,
    monthAvgBidAmount: undefined,
  });

  const columns = useHeader({
    programParticipantStatus,
    networkPenaltyTypes,
    networkDiscountTypes,
    selected,
    setSelected,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          break;

        case "edit":
          setAction([]);
          break;

        default:
          break;
      }
    },
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      await NetworkApi.set_limit({
        businessIds:
          selected?.map((item: any) => {
            return item.id;
          }) || [],
        debtLimitAmount: values.debtLimitAmount,
        debtLimitStartDate: values.debtLimitStartDate,
        debtLimitEndDate: values.debtLimitEndDate,
        earlyDiscount: values.earlyDiscount,
        discountType: values.discountType,
        discountPercent: values.discountPercent,
        countPenalty: values.countPenalty,
        penaltyType: values.penaltyType,
        penaltyPercent: values.penaltyPercent,
        weekAvgDebtAmount: values.weekAvgDebtAmount,
        halfMonthAvgDebtAmount: values.halfMonthAvgDebtAmount,
        monthAvgDebtAmount: values.monthAvgDebtAmount,
        monthAvgPurchaseAmount: values.monthAvgPurchaseAmount,
        monthAvgRefundAmount: values.monthAvgRefundAmount,
        monthAvgBidAmount: values.monthAvgBidAmount,
      });
      message.success("Таны үйлдэл амжилттай.");
      setAction([]);
      reload();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue, errors }) => {
        console.log("Errors:::::>", errors);
        return (
          <div>
            <LoadingOverlay visible={loading} />
            <Grid>
              {/* <Grid.Col>
                <Text fw={500} color="dimmed" w="max-content">
                  Нэхэмжлэхийг санхүүжүүлэхэд шаардлагатай мэдээлэл
                </Text>
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="debtLimitAmount" label="Нийлүүлэгчээс олгосон авлагын лимит" placeholder="Дүн оруулна уу" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <DatePickerField name="debtLimitStartDate" label="Лимит эхлэх огноо" placeholder="Огноо сонгоно уу" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <DatePickerField name="debtLimitEndDate" label="Лимит дуусах огноо" placeholder="Огноо сонгоно уу" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <CheckboxField
                  name="earlyDiscount"
                  label="Хугацаанаас өмнө хөнгөлдөг эсэх"
                  children="Тийм"
                  onChange={() => {
                    setFieldValue("discountPercent", undefined);
                    setFieldValue("discountType", null);
                  }}
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberField
                  disabled={!values.earlyDiscount}
                  precision={2}
                  rightSectionWidth={60}
                  name="discountPercent"
                  label="Хөнгөлөлтийн хувь"
                  placeholder="Хувийг оруулна уу"
                  rightSection={
                    <Text size={"sm"} color="dimmed">
                      / Хувь
                    </Text>
                  }
                  required
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <SelectField
                  disabled={!values.earlyDiscount}
                  options={networkDiscountTypes.map((item: IReference) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  name="discountType"
                  label="Хөнгөлөлт тооцдог арга"
                  placeholder="Бүтэн төлсөн тохиолдолдд дүнгээс"
                  required
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <CheckboxField
                  name="countPenalty"
                  label="Хугацаанаас хойш алданги тооцдог эсэх"
                  children="Тийм"
                  onChange={() => {
                    setFieldValue("penaltyPercent", undefined);
                    setFieldValue("penaltyType", null);
                  }}
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberField
                  disabled={!values.countPenalty}
                  precision={2}
                  rightSectionWidth={60}
                  name="penaltyPercent"
                  label="Алдангийн хувь"
                  placeholder="Хувийг оруулна уу"
                  rightSection={
                    <Text size={"sm"} color="dimmed">
                      / Хувь
                    </Text>
                  }
                  required
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <SelectField
                  disabled={!values.countPenalty}
                  options={networkPenaltyTypes.map((item: IReference) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  name="penaltyType"
                  label="Алданги тооцох арга"
                  placeholder="Төлөөгүй үнийн дүнгээс хоног тутамд"
                  required
                />
              </Grid.Col> */}
              <Grid.Col>
                <Text fw={500} color="dimmed" w="max-content">
                  Өмнөх түүхэнд үндэслэн оруулах
                </Text>
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="weekAvgDebtAmount" label="Өглөгийн дундаж үлдэгдэл - 7 хоног" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="halfMonthAvgDebtAmount" label="Өглөгийн дундаж үлдэгдэл - 2 д.хо" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="monthAvgDebtAmount" label="Өглөгийн дундаж үлдэгдэл - Сар" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="monthAvgPurchaseAmount" label="Сарын дундаж нийлүүлэлт" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="monthAvgRefundAmount" label="Сарын буцаалтын дундаж дүн" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={4}>
                <NumberCurrencyField name="monthAvgBidAmount" label="Сарын дундаж худалдан авалт" placeholder="Дүн" required />
              </Grid.Col>
              <Grid.Col>
                <Text fw={500} color="dimmed" w="max-content">
                  Сонгосон бизнесүүд
                </Text>
              </Grid.Col>
              <Grid.Col>
                {selected.length > 0 ? (
                  <Table minWidth={2400} name="requests.list.static" columns={columns} dataSource={selected} />
                ) : (
                  <Alert color="red" icon={<IconAlertCircle />} title="Анхааруулга">
                    Та хамгийн багадаа нэг бизнес сонгосон байх шаардлагатай.
                  </Alert>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex justify="space-between">
                  <Button
                    onClick={() => {
                      setSelected([]);
                      setAction([]);
                    }}
                    variant="light"
                    color="gray">
                    Болих
                  </Button>
                  <Button loading={loading} type="submit" variant="filled" color={"golomt"}>
                    Баталгаажуулах
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </div>
        );
      }}
    </Form>
  );
}

const useHeader = ({
  onClick,
  programParticipantStatus,
  networkPenaltyTypes,
  networkDiscountTypes,
  selected,
  setSelected,
}: {
  onClick: (e1: string, e2: any) => void;
  programParticipantStatus: IReference[];
  networkPenaltyTypes: IReference[];
  networkDiscountTypes: IReference[];
  selected: any[];
  setSelected: (e1: any) => void;
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    dataIndex: "description",
    render: (record) => {
      return (
        <Group noWrap>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Хасах" position="bottom">
            <ActionIcon
              onClick={() => {
                if (selected.some((item: any) => item.id === record.id)) {
                  setSelected(selected.filter((item: any) => item.id !== record.id));
                }
              }}
              variant="light"
              color={"golomt"}>
              <IconCircleMinus />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{record?.regNumber || "-"}</Text>;
    },
  },
  {
    title: "Партнерийн нэр",
    dataIndex: "sku",
    render: (record) => {
      if (record?.partner)
        return (
          <Text w={"max-content"}>
            <Anchor>{record?.partner?.businessName || "-"}#,</Anchor>
            <Text>{record?.partner?.refCode || "-"}</Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Buyer бизнесийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return record?.profileName ? (
        <Text w={"max-content"}>
          <Anchor>{record?.profileName || "-"}#,</Anchor>
          <Text>{record?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    dataIndex: "sku",
    render: (record) => {
      return Tag(
        programParticipantStatus?.find((item: IReference) => item?.code === record?.participantStatus)?.color || "gray",
        programParticipantStatus?.find((item: IReference) => item?.code === record?.participantStatus)?.name || "-",
      );
    },
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaRegion?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Чиглэл",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaDirection?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    dataIndex: "sku",
    render: (record) => {
      return record?.paymentTerm ? (
        <Text w={"max-content"}>
          <Text color={"golomt"}># {record?.paymentTerm?.refCode}</Text>
          <Text color="dimmed">{record?.paymentTerm?.description}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгчээс олгосон авлага лимит",
    dataIndex: "sku",
    width: 120,
    render: (record) => <Text align="end">{currencyFormat(record?.debtLimitAmount || 0)}</Text>,
  },
  {
    title: "Лимит эхлэх огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitStartDate)}</Group>;
    },
  },
  {
    title: "Лимит дуусах огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitEndDate)}</Group>;
    },
  },
  {
    title: "Хөнгөлөлттэй эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Switch checked={record?.earlyDiscount} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </div>
      );
    },
  },
  {
    title: "Хөнгөлөлт хувь",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{record?.discountPercent || 0} %</Group>;
    },
  },
  {
    title: "Хөнгөлөлт арга",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          {networkDiscountTypes?.length > 0 ? networkDiscountTypes.find((item: IReference) => item.code === record.discountType)?.name || "-" : "-"}
        </Text>
      );
    },
  },
  {
    title: "Алдангитай эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <Switch checked={record?.countPenalty} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </Group>
      );
    },
  },
  {
    title: "Алданги (хувь)",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{record?.penaltyPercent || 0} %</Group>;
    },
  },
  {
    title: "Алданги арга",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{networkPenaltyTypes?.find((item: IReference) => item.code === record.penaltyType)?.name || "-"} </Group>;
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 7 хоног",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.weekAvgDebtAmount)}</Group>;
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 14 хоног",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.halfMonthAvgDebtAmount)}</Group>;
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - Сар",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.monthAvgDebtAmount)}</Group>;
    },
  },
  {
    title: "Буцаалтын дундаж дүн - Сар",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.monthAvgRefundAmount)}</Group>;
    },
  },
  {
    title: "Сарын дундаж татан авалт",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.monthAvgPurchaseAmount)}</Group>;
    },
  },
  {
    title: "Сарын дундаж орлого тушаалт",
    dataIndex: "sku",
    render: (record) => {
      return <Group position="right">{currencyFormat(record?.monthAvgBidAmount)}</Group>;
    },
  },
  {
    title: "Ангилал",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationCategory)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationCategory?.name || "-"}</Text>
            <Text size={"sm"} color="dimmed" truncate>
              {record?.classificationCategory?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Зэрэглэл",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationPriority)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationPriority?.name || "-"}</Text>
            <Text maw={"320px"} size={"sm"} color="dimmed" truncate>
              {record?.classificationPriority?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Хариуцсан санхүү",
    dataIndex: "sku",
    render: (record) => {
      if (record?.areaSetUser)
        return (
          <Group noWrap>
            <Avatar src={record?.areaSetUser?.avatar || ""} />
            <Text>
              {record?.areaSetUser?.lastName ? record.areaSetUser.lastName[0] + ". " : ""} {record?.areaSetUser?.firstName || ""}
            </Text>
          </Group>
        );
      else return "-";
    },
  },
];
