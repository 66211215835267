import { ActionIcon, Avatar, Flex, Paper, Space, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import { Tag } from "./../../components/tags";
import { CompromiseApi, RepaymentApi } from "../../apis";

export function PromiseHistory({ action }: { action: any[] }) {
  const {
    currencies,
    productSuppFeeRules,
    repaymentStatus = [],
    programRepaymentRules = [],
    repaymentPaymentStatus,
    repaymentOverDueStatus,
  } = useSelector((state: { general: IGeneral }) => state.general);

  const [filters, setFilters] = useState({ repaymentId: action[1]?.id });

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail":
          // setAction(["promise", record]);
          break;

        default:
          break;
      }
    },
    data: action[1],
    Tag,
    repaymentStatus,
    currencies,
    productSuppFeeRules,
    programRepaymentRules,
    repaymentPaymentStatus,
    repaymentOverDueStatus,
  });

  console.log(action);

  return (
    <Paper>
      <Space mt={"lg"} />
      <Table
        name="repayment.compromise.list"
        columns={columns}
        filters={filters}
        minWidth={2400}
        placeholder="Амлалт өгөөгүй байна."
        loadData={(data) => CompromiseApi.list(data!)}
      />
    </Paper>
  );
}

const useHeader = ({
  onClick,
  programRepaymentRules,
  repaymentPaymentStatus,
  repaymentOverDueStatus,
  repaymentStatus,
  Tag,
  data,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  productSuppFeeRules: any;
  programRepaymentRules: any;
  repaymentPaymentStatus: IReference[];
  repaymentOverDueStatus: any;
  repaymentStatus: IReference[];
  Tag: any;
  data: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Амлалт Код",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text color="golomt" fw={500}>
        # {record?.refCode}
      </Text>
    ),
  },
  {
    title: "Төлөх дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="dimmed" w="max-content">
          {currencyFormat(record?.amount)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлөх огноо",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text fw={500} color="dimmed" w="max-content">
        {dateTimeFormat(record.date)}
      </Text>
    ),
  },
  {
    title: "Үүсгэсэн огноо",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {dateTimeFormat(record?.createdAt)}
      </Text>
    ),
  },
  {
    title: "Амлалт өгсөн",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Flex gap={"xs"} align={"center"}>
        <Avatar src={record?.actionUser.avatar} size={32} />
        <Text>
          {record?.actionUser.lastName ? record?.actionUser.lastName[0] + ". " : ""}
          {record?.actionUser.firstName}
        </Text>
      </Flex>
    ),
  },
  // {
  //   title: "Үйлдэл",
  //   dataIndex: "description",
  //   render: (record) => {
  //     return (
  //       <Tooltip label="Дэлгэрэнгүй" position="bottom">
  //         <ActionIcon color="golomt" variant="filled" onClick={() => onClick("detail", record)}>
  //           <IconInfoSquare />
  //         </ActionIcon>
  //       </Tooltip>
  //     );
  //   },
  // },
];
