import { Anchor, Avatar, Checkbox, Flex, Group, Text } from "@mantine/core";
import { SetStateAction } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FinanceableApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";
import dayjs from "dayjs";

export const BuyerLBFList = ({
  setAction,
  action,
  setSelectedInv,
  selectedInv,
  tableRefLbf,
}: {
  setAction: React.Dispatch<SetStateAction<any[]>>;
  action: any[];
  setSelectedInv: React.Dispatch<SetStateAction<string>>;
  selectedInv: string;
  tableRefLbf: any;
}) => {
  const params = useParams();
  const { programStatus, currencies, invoiceStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    invoicePaymentStatus,
    currencies,
    programStatus,
    setSelectedInv,
    selectedInv,
    invoiceStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          setAction(["details", item]);
          break;

        default:
          break;
      }
    },
  });

  return (
    <>
      {params.id && (
        <Table
          ref={tableRefLbf}
          minWidth={2400}
          name="invoice.lbf.list"
          columns={columns}
          filters={{ programId: params.id, financeType: "LBF" }}
          loadData={(data) => FinanceableApi.list(data!)}
        />
      )}
    </>
  );
};

const useHeader = ({
  onClick,
  programStatus,
  currencies,
  setSelectedInv,
  selectedInv,
  invoiceStatus,
  invoicePaymentStatus,
}: {
  onClick: (e1: string, e2: any) => void;
  programStatus: IReference[];
  currencies: IReference[];
  setSelectedInv: React.Dispatch<SetStateAction<string>>;
  selectedInv: string;
  invoiceStatus: IReference[];
  invoicePaymentStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Сонгох",
    dataIndex: "action",
    render: (record, index) => (
      <div>
        <Checkbox
          checked={record.id === selectedInv}
          onChange={() => {
            if (record.id === selectedInv) setSelectedInv("");
            else setSelectedInv(`${record.id}`);
          }}
        />
      </div>
    ),
  },
  {
    title: "Баталсан",
    dataIndex: "confirmedDate",
    render: (record) => {
      return <Text w={"max-content"}>{record.confirmedDate ? dateTimeFormat(record.confirmedDate) : "-"} </Text>;
    },
  },
  {
    title: "ТТД",
    dataIndex: "senderBusiness-regNumber",
    render: (record) => {
      return <Text>{record?.type !== "SALES" ? record?.senderBusiness?.partner?.regNumber : record?.receiverBusiness?.partner?.regNumber}</Text>;
    },
  },
  {
    title: "Партнер",
    dataIndex: "senderBusiness.partner",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Худалдан авагч",
    dataIndex: "SALES ? receiverBusiness : senderUser",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.refCode}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" w={"max-content"}>
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Нэхэмжлэх",
    dataIndex: "refCode",
    render: (record) =>
      record?.refCode ? (
        <Text w="max-content">
          # <Anchor>{record.refCode}</Anchor>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Үлдэгдэл төлбөр",
    dataIndex: "amountToPay",
    render: (record) => (
      <Group position="right">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.amountToPay ? currencyFormat(record?.amountToPay || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: "Валют",
    dataIndex: "currency",
    render: (record) => <Text w="max-content">{currencies?.find((f: any) => f?.code === record?.currency)?.name || "Төгрөг"}</Text>,
  },
  {
    title: "Төлөх огноо",
    dataIndex: "paymentDate",
    render: (record) => <Text w="max-content">{record?.paymentDate ? dateTimeFormat(record.paymentDate) : "-"}</Text>,
  },
  {
    title: "Үлдсэн хоног",
    dataIndex: "paymentDate/nowDate",
    render: (record) => <Text w="max-content">{record?.paymentDate ? dayjs(record?.paymentDate).diff(new Date(), "days") : "-"} хоног</Text>,
  },
  {
    title: "Төлсөн дүн",
    dataIndex: "paidAmount",
    render: (record) => (
      <Group position="right">
        <Text w="max-content"> {currencyFormat(record?.paidAmount || 0)} </Text>
      </Group>
    ),
  },
  {
    title: "Төлбөр статус",
    dataIndex: "invoiceStatus",
    render: (record) => (
      <>
        {record?.paymentStatus
          ? Tag(
              invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.color || "blue",
              invoicePaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.name || "-",
            )
          : "-"}
      </>
    ),
  },
  {
    title: "Баталсан дүн",
    dataIndex: "confirmedAmount",
    render: (record) => (
      <Group position="right">
        <Text w="max-content"> {currencyFormat(record?.confirmedAmount || 0)} </Text>
      </Group>
    ),
  },
  {
    title: "Нэхэмжлэх статус",
    dataIndex: "invoiceStatus",
    render: (record) => (
      <>
        {record?.invoiceStatus
          ? Tag(
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.color || "blue",
              invoiceStatus.find((item: IReference) => item.code === record?.invoiceStatus)?.name || "-",
            )
          : "-"}
      </>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    dataIndex: "confirmedUser",
    render: (record) =>
      record?.confirmedUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.confirmedUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.confirmedUser?.lastName ? record?.confirmedUser?.lastName[0] + ". " : ""} {record?.confirmedUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Нэхэмжлэх илгээсэн",
    dataIndex: "senderUser",
    render: (record) =>
      record?.senderUser ? (
        <Flex gap="xs" align="center">
          <Avatar radius={100} src={record?.senderUser?.avatar} />
          <Flex direction="column" gap="xs">
            <Text fw={500} color="golomt" w={"max-content"} tt={"capitalize"}>
              {record?.senderUser?.lastName ? record?.senderUser?.lastName[0] + ". " : ""} {record?.senderUser?.firstName || "-"}
            </Text>
          </Flex>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Захиалга №",
    dataIndex: "orderSalesCode",
    render: (record) => {
      return record?.orderSalesCode ? (
        <Text w="max-content">
          <Anchor>{record?.orderSalesCode}</Anchor>
        </Text>
      ) : (
        "-"
      );
    },
  },
];
