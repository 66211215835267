import { Badge, Flex, Grid, Input, Paper, Space, Switch, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { dateFormat, dateTimeFormat } from "../../utils/date";
import { NumberCurrencyField } from "../form/number-currency-field";

export const LendDetailsRequestSupplier = ({ action, values }: { action: any[]; values: { requestedAmount: number } }) => {
  const data: null | any = action[1] && action[1];
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { lbfProgram } = useSelector((state: { programs: any }) => state.programs);

  const booleanIcon = (min: number, mid: number, max: number) => {
    switch (true) {
      case min > mid || mid > max: {
        return <IconX size={20} color="red" />;
      }
      case min <= mid && mid <= max: {
        return <IconCheck size={20} color="green" />;
      }

      default: {
        return "-";
      }
    }
  };

  // dispatch(getLbfProgramReduce(item));

  return (
    <Paper py={"lg"}>
      <Text color="gray.8" fw={600}>
        Хүсэлтийн ерөнхий мэдээлэл
      </Text>
      <Space h={"lg"} />
      <Grid>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Код, огноо цаг">
            <div>
              <Text component="span" fz={"sm"} color="gray.8">
                {"Авто үүснэ"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {dateFormat(new Date())}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт гаргасан ажилтан">
            <Text color="golomt" fz={"sm"} tt={"capitalize"}>
              {data?.senderFinUser?.lastName ? data?.senderFinUser?.lastName[0] + ". " : ""}
              {data?.senderFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлтийн төлөв">
            <div>
              <Badge color="gray" variant="outline">
                {"Түр төлөв"}
              </Badge>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн код">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {user?.clientCode || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt" tt={"capitalize"}>
                # {data?.senderBusiness?.partner?.refCode || "-"}
              </Text>
              <Text fz={"sm"} component="span">
                , {data?.senderBusiness?.partner?.businessName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.senderBusiness?.regNumber || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт хамаарах бизнес">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.senderBusiness?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.senderBusiness?.profileName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.program?.refCode || lbfProgram?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.program?.name || lbfProgram?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Бүтээгдэхүүний нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.program?.lbfProduct?.refCode || lbfProgram?.lbfProduct?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.program?.lbfProduct?.name || lbfProgram?.lbfProduct?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text color="gray.8" fw={600}>
            Нэхэмжлэхийн шалгуур хангах байдал
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилт хүсэх дүн">
            <div>
              <NumberCurrencyField name="requestedAmount" placeholder="Finance_Amount" disabled={action[0] === "details"} />
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {data?.paymentDate ? dateTimeFormat(data?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Зээл төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {data?.paymentDate ? dateTimeFormat(data?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилтийн хэмжээ шалгуур">
            <Flex align="center" gap={"xs"}>
              {booleanIcon(
                (data?.amountToPay * data?.program?.lbfProduct?.minOfInv) / 100,
                values.requestedAmount,
                (data?.amountToPay * data?.program?.lbfProduct?.maxOfInv) / 100,
              )}
              <Text component="span" fz={"sm"}>
                {currencyFormat((data?.amountToPay * data?.program?.lbfProduct?.minOfInv) / 100)} -{" "}
                {currencyFormat((data?.amountToPay * data?.program?.lbfProduct?.maxOfInv) / 100)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл">
            <Flex align="center" gap={"xs"}>
              <IconCheck size={"1.2rem"} color="green" />
              <Text component="span" fz={"sm"}>
                {currencyFormat(data?.amountToPay)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх Tenor">
            <div>
              <Flex gap="xs" align="center">
                {booleanIcon(
                  data?.program?.lbfProduct?.minTenor,
                  Math.abs(Number(dayjs(data?.paymentDate).diff(dayjs(), "day"))),
                  data?.program?.lbfProduct?.maxTenor,
                )}
                <Text fz="sm" fw={500}>
                  {data?.program?.lbfProduct?.minTenor} - {data?.program?.lbfProduct?.maxTenor}
                </Text>
              </Flex>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх хугацаа">
            <Flex align="center" gap={"xs"}>
              {dayjs(new Date()).diff(data?.confirmedDate, "days") > 0 ? <IconCheck size={"1.2rem"} color="green" /> : <IconX size={"1.2rem"} color="red" />}
              <Text component="span" fz={"sm"}>
                {data?.confirmedDate ? dayjs(new Date()).diff(data?.confirmedDate, "days") : "-"} хоног
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах эсэх">
            <Flex pt={"4px"}>
              <Switch
                checked={data?.program?.lbfProduct?.recourseTerm || false}
                onChange={() => {}}
                offLabel={<Text fz={"10px"}>Үгүй</Text>}
                onLabel={<Text fz={"10px"}>Тийм</Text>}
              />
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах огноо">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {dayjs(data?.paymentDate)
                  .add(data?.program?.lbfProduct?.recourseDays || 0, "days")
                  .format("YYYY-MM-DD hh:mm")}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Олголт хүлээн авах данс">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.receiverAcc?.number}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.receiverAcc?.name}, {data?.receiverAcc?.bankName},{" "}
                <Text component="span" color="gray" fz={"xs"}>
                  ({data?.receiverAcc?.shortName})
                </Text>
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      {/* <pre>
        <Code>
          <>{JSON.stringify(data, null, 2)}</>
          <>{JSON.stringify({ program: lbfProgram }, null, 2)}</>
        </Code>
      </pre> */}
    </Paper>
  );
};
