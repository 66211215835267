import { Button, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { PinApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { NumberField } from "../form/number-field";

const schema = yup.object({
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(999999, "Хэт урт байна!")
    .min(0, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

type IForm = {
  pin: string | undefined;
};

export function RepaymentPinForm({
  onClose,
  sendRequest,
  loading,
  setLoading,
}: {
  onClose: () => void;
  sendRequest: () => void;
  setLoading: any;
  loading: boolean;
}) {
  const [data] = React.useState<IForm>({
    pin: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      const res = await PinApi.check({
        pin: `${values.pin}`,
      });

      if (res) {
        sendRequest();
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid>
            <LoadingOverlay visible={loading} />
            <Grid.Col span={12}>
              <Text align="center" color="#2a3536">
                Ta SCF хүсэлт батлах гэж байна!
              </Text>
              <Text align="center" color="#2a3536">
                Ta төлбөрийн мэдээллээ үнэн зөвийг баталгаажуулсан бол{" "}
                <Text color="dark" fw={600} span>
                  ПИН
                </Text>
                кодоо хийж батлана уу
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="center">
                <NumberField name="pin" placeholder="______" />
              </Flex>
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="space-between">
                <Button onClick={() => onClose()} variant="light" color="gray">
                  Болих
                </Button>
                <Button type="submit" variant="filled" color={"golomt"}>
                  Баталгаажуулах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
