import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => await httpRequest.get("/api/network", data);

export const set_limit = async (data: IForm) => await httpRequest.put(`/api/network/set_limit`, data as IForm);

type IForm = {
  businessIds: string[];
  debtLimitAmount: number | undefined;
  debtLimitStartDate: string | undefined;
  debtLimitEndDate: string | undefined;
  earlyDiscount: boolean;
  discountType: string | undefined;
  discountPercent: number | undefined;
  countPenalty: boolean;
  penaltyType: string | undefined;
  penaltyPercent: number | undefined;
  weekAvgDebtAmount: number | undefined;
  halfMonthAvgDebtAmount: number | undefined;
  monthAvgDebtAmount: number | undefined;
  monthAvgPurchaseAmount: number | undefined;
  monthAvgRefundAmount: number | undefined;
  monthAvgBidAmount: number | undefined;
};
