import { Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "../tags";

export const TabInvoiceRequestFunding = ({ data }: { data: any }) => {
  const { invoiceStatus, invoicePaymentStatus, invoiceOverdueStatus } = useSelector((state: { general: IGeneral }) => state.general);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Divider
            label={
              <Text color="golomt" fw={600} size={"md"}>
                Ерөнхий мэдээлэл
              </Text>
            }
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн код:">
            <Text fz="sm" fw={500} color="golomt">
              # {data?.invRefCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх илгээсэн:">
            <Text fz="sm" fw={500}>
              {data?.invSenderUser?.lastName ? data?.invSenderUser?.lastName[0] + ". " : ""} {data?.invSenderUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх баталсан:">
            <Text fz="sm" fw={500}>
              {data?.invConfirmedUser?.lastName ? data?.invConfirmedUser?.lastName[0] + ". " : ""} {data?.invConfirmedUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Баталсан дүн:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.invConfirmedAmount || 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлсөн дүн:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.invPaidAmount || 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:">
            <Text fz="sm" fw={500}>
              {currencyFormat(data?.invAmountToPay || 0)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoiceStatus?.find((item: IReference) => item.code === data?.invStatus)?.color || "gray",
                invoiceStatus?.find((item: IReference) => item.code === data?.invStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.invPaymentStatus)?.color || "gray",
                invoicePaymentStatus?.find((item: IReference) => item.code === data?.invPaymentStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв:">
            <Text fz="sm" fw={500}>
              {Tag(
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.invOverdueStatus)?.color || "gray",
                invoiceOverdueStatus?.find((item: IReference) => item.code === data?.invOverdueStatus)?.name || "-",
              )}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Төлбөрийн нөхцөл:">
            <Text fz="sm" fw={500}>
              {data?.invPaymentTermDesc || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
            <Text fz="sm" fw={500}>
              {data?.invPaymentDate ? dateTimeFormat(data?.invPaymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн баримт:">
            <div>
              <Button disabled variant="light" size="xs" leftIcon={<IconDownload size={18} color="#15AABF" />}>
                INV_3222.PDF
              </Button>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider
            label={
              <Text c="golomt" fw={600} size={"md"}>
                Нийлүүлэгч талын мэдээлэл
              </Text>
            }
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр:">
            <Flex gap={8}>
              <Text fz="sm" fw={500}>
                {data?.type === "SUPPLIER" ? (
                  <>{data?.requestedBusiness?.partner?.businessName || "-"}</>
                ) : (
                  <>{data?.invReceiverBusiness?.partner?.businessName || "-"}</>
                )}
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                # {data?.type === "SUPPLIER" ? <>{data?.requestedBusiness?.partner?.refCode}</> : <>{data?.invReceiverBusiness?.partner?.refCode}</>}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар:">
            <Text fz="sm" fw={500}>
              {data?.type === "SUPPLIER" ? <>{data?.requestedBusiness?.regNumber}</> : <>{data?.invReceirverBusiness?.refCode}</>}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгч бизнес:">
            {data?.type === "SUPPLIER" ? (
              <Flex gap={8}>
                <Text fz="sm" fw={500}>
                  {data?.requestedBusiness?.profileName}
                </Text>
                ,{" "}
                <Text fz="sm" fw={500} color="golomt">
                  #{data?.requestedBusiness?.refCode}
                </Text>
              </Flex>
            ) : (
              <>
                <Flex gap={8}>
                  <Text fz="sm" fw={500} color="golomt">
                    {data?.invReceiverBusiness?.profileName}
                  </Text>
                  ,
                  <Text fz="sm" fw={500}>
                    #{data?.invReceiverBusiness?.refCode}
                  </Text>
                </Flex>
              </>
            )}
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нийлүүлэгчийн данс:">
            <Text fz="sm" fw={500}>
              {data?.requestedBusinessAcc?.name || "-"}, {data?.requestedBusinessAcc?.number || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Борлуулалтын захиалга:">
            <Text fz="sm" fw={500}>
              {data?.salesOrder?.refCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан:">
            <Text fz="sm" fw={500}>
              {data?.requestedFinUser?.lastName ? data?.requestedFinUser?.lastName[0] + ". " : ""} {data?.requestedFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider
            label={
              <Text fw={600} size={16} color={"golomt"}>
                Худалдан авагч талын мэдээлэл
              </Text>
            }
          />
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Партнерийн нэр:">
            <Flex gap={8}>
              <Text fz="sm" fw={500}>
                {data?.invReceiverBusiness?.partner?.businessName || "-"}
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.invReceiverBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар:">
            <Text fz="sm" fw={500}>
              {data?.invReceiverBusiness?.regNumber || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагч бизнес:">
            <Flex align={"center"} gap={8}>
              <Text fz="sm" fw={500}>
                {data?.invReceiverBusiness?.profileName || "-"},
              </Text>
              <Text fz="sm" fw={500} color="golomt">
                #{data?.invReceiverBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авагч данс:">
            <Text fz="sm" fw={500}>
              {data?.invReceiverBusinessAcc?.name || "-"}, {data?.invReceiverBusinessAcc?.number || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Худалдан авалтын захиалга:">
            <Text fz="sm" fw={500}>
              {data?.purchaseOrder?.refCode || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүгийн ажилтан:">
            <Text fz="sm" fw={500}>
              {data?.finUser?.lastName ? data?.finUser?.lastName[0] + ". " : ""} {data?.finUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
