import { store } from "../store";
import { authChange } from "../store/Auth";
import { HttpHandler } from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

const percent = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/gm;
const currencyRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const dayRegex = /^[0-9\b]{1,3}$/gm;

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): HttpHandler => {
    if (statusCode === 401) {
      store.dispatch(authChange());
    }

    throw error;
  };
}

function currencyFormat(num: number) {
  return (
    parseFloat(`${num}`)
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " ₮"
  );
}

export { percent, currencyRegex, dayRegex, currencyFormat };
