import { createStyles, Flex, Grid, Input, Paper, Switch, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const FinanceConditions = ({ data }: { data?: any }) => {
  const { theme } = useStyle();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productSuppFeeRules, productBuyerFeeRules, productSuppPenaltyTypes, productBuyerPenaltyTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const myType = user?.currentBusiness?.type;

  return (
    <Paper mt="lg">
      <Grid>
        <Grid.Col>
          <Text c={"gray.6"} fw={500}>
            Нэхэмжлэх санхүүжүүлэх үеийн нөхцөл
          </Text>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн доод үлдэгдэл">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {currencyFormat(data?.product?.minInvAmount)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Min хувь">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.minOfInv} %
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Max хувь">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.maxOfInv} %
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Min Tenor">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.minTenor} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Max Tenor">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.maxTenor} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Min хугацаа">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.minDays} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн Max хугацаа">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.maxDays} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах эсэх">
            <Flex align={"center"} gap={8} mt="5px">
              <Switch
                checked={data?.product?.recourseTerm || false}
                onChange={() => {}}
                offLabel={<Text fz={"10px"}>Үгүй</Text>}
                onLabel={<Text fz={"10px"}>Тийм</Text>}
              />
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Буцаан дуудах хугацаа">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {data?.product?.recourseDays} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилт зогсоох хугацаа">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && data?.product?.suppTerminatePeriod}
              {myType === "BUYER" && data?.product?.buyerTerminatePeriod} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text c={"gray.6"} fw={500}>
            Шимтгэл хураамжийн мэдээлэл
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх санхүүжүүлэх хүү">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && data?.product?.suppFee}
              {myType === "BUYER" && data?.product?.buyerFee} %
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүү тооцох хоног">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && data?.product?.suppCalculateDay}
              {myType === "BUYER" && data?.product?.buyerCalculateDay} хоног
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Олголтын шимтгэл">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && data?.product?.suppDisbursementFee}
              {myType === "BUYER" && data?.product?.buyerDisbursementFee} %
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Өргөдлийн хураамж">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && currencyFormat(data?.product?.suppAppFee)}
              {myType === "BUYER" && currencyFormat(data?.product?.buyerAppFee)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Минимум шимтгэлийн дүн">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && currencyFormat(data?.product?.suppMinFee)}
              {myType === "BUYER" && currencyFormat(data?.product?.buyerMinFee)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Шимтгэл авах дүрэм">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && (productSuppFeeRules.find((item: IReference) => item.code === data?.product?.suppFeeRule)?.name || "-")}
              {myType === "BUYER" && (productBuyerFeeRules.find((item: IReference) => item.code === data?.product?.buyerFeeRule)?.name || "-")}
            </Text>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хугацаа хэтэрсний алданги">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && data?.product?.suppPenaltyPercent}
              {myType === "BUYER" && data?.product?.buyerPenaltyPercent} %
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Алданги тооцох дүрэм">
            <Text fz={"sm"} fw={500} color={theme.primaryColor}>
              {myType === "SUPPLIER" && (productSuppPenaltyTypes.find((item: IReference) => item.code === data?.product?.suppPenaltyType)?.name || "-")}
              {myType === "BUYER" && (productBuyerPenaltyTypes.find((item: IReference) => item.code === data?.product?.buyerPenaltyType)?.name || "-")}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

const useStyle = createStyles((theme) => ({}));
