import { ActionIcon, Button, Center, Divider, Flex, Grid, Group, Paper, Space, Text } from "@mantine/core";
import { IconX } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { CompromiseApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ReactComponent as GolomtSvg } from "./../../assets/bogd-logo.svg";
import dayjs from "dayjs";

const schema = yup.object({
  schedule: yup.array().of(
    yup.object().shape({
      amount: yup.string().min(0, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      date: yup.string().required("Заавал бөглөнө!").nullable(),
    }),
  ),
});

export function PaymentMatching({ setAction, action, reload }: { setAction: (e: any[]) => void; action: any; reload: () => void }) {
  const propsData: any = action[1];
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    schedule: [
      {
        amount: undefined,
        date: undefined,
      },
    ],
  });

  const onSubmit = async (value: any) => {
    setLoading(true);
    console.log(value);
    try {
      await CompromiseApi.create(propsData.id, value?.schedule);

      message.success("Үйлдэл амжилттай.");
      setAction([]);
      reload();
      setLoading(false);
    } catch (error: any) {
      error.message && message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <Paper>
      <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Grid>
              <Grid.Col span={7}>
                <Paper withBorder p={"md"}>
                  {/* <pre>{JSON.stringify(propsData, null, 2)}</pre> */}
                  <Flex align="center" justify="space-between">
                    <Flex gap={8}>
                      <Text fz="lg">ЭТН №:</Text>
                      <Text fz="lg" color="golomt" fw={600}>
                        {propsData?.refCode || "-"}#
                      </Text>
                    </Flex>
                    <Flex gap={8}>
                      <Text fz="lg">ТТД: </Text>
                      <Text fz="lg" color="golomt">
                        {propsData?.payerBusiness?.regNumber || "-"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Divider my={"lg"} />
                  <Center>
                    <Text align="center">
                      Хүндэт{" "}
                      <Text component="span" color="golomt">
                        {user?.lastName ? user?.lastName[1] + ". " : ""}
                      </Text>{" "}
                      <Text component="span" color="golomt">
                        {user?.firstName || "-"}
                      </Text>
                    </Text>
                  </Center>
                  <Center>
                    <Text component="p" align="center">
                      Таны төлөөлж буй{" "}
                      <Text component="span" color="golomt">
                        {propsData?.payerBusiness?.partner?.businessName || "-"}, {propsData?.payerBusiness?.partner?.refCode || "-"}
                      </Text>{" "}
                      -ийн{" "}
                      <Text component="span" color="golomt">
                        {propsData?.payerBusiness?.profileName || "-"}, {propsData?.payerBusiness?.refCode || "-"}
                      </Text>
                      -ийн{" "}
                      <Text component="span" color="golomt">
                        {propsData?.refCode}
                      </Text>{" "}
                      дугаартай эргэн төлөлтийн нэхэмжлэх{" "}
                      <Text component="span" color="golomt">
                        {currencyFormat(propsData?.amountToPay)}
                      </Text>{" "}
                      төгрөгийн үлдэгдэлтэйгээр{" "}
                      <Text component="span" color="golomt">
                        {Math.abs(propsData?.remainingDays)}
                      </Text>{" "}
                      хоногоор хугацаа хэтэрсэн байна.
                    </Text>
                  </Center>
                  <Flex align="center" justify="space-between">
                    <Text mb="md" fw={600} size={"lg"} color="gray.8">
                      Төлөх хуваарь тохирох
                    </Text>
                    <Button
                      onClick={() => {
                        setFieldValue("schedule", [
                          ...values.schedule,
                          {
                            date: undefined,
                            amount: undefined,
                          },
                        ]);
                      }}
                      radius={"xl"}>
                      Хуваарь нэмэх
                    </Button>
                  </Flex>
                  {values?.schedule?.map((item: any, index: number) => {
                    return (
                      <Grid key={index}>
                        <Grid.Col sm={12} md={7}>
                          <NumberCurrencyField required label={`Төлөх боломжит дүн:`} name={`schedule[${index}].amount`} placeholder="0.00" />
                        </Grid.Col>
                        <Grid.Col sm={12} md={5}>
                          <Flex w={"100%"}>
                            <DatePickerField
                              label="Огноо цаг сонгох"
                              name={`schedule[${index}].date`}
                              placeholder="Огноо цаг сонгох"
                              excludeDate={(date: any) => {
                                return dayjs(date).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD");
                              }}
                            />
                            <ActionIcon
                              onClick={() => {
                                if (index === 0) {
                                  setFieldValue(`schedule[${index}]`, { amount: undefined, date: undefined });
                                } else {
                                  setFieldValue(
                                    "schedule",
                                    values?.schedule?.filter((item: any, index2: number) => index2 !== index),
                                  );
                                }
                              }}
                              color="golomt"
                              mt={"24px"}
                              ml={"xs"}
                              variant="light"
                              size={"xs"}
                              radius="xl">
                              <IconX />
                            </ActionIcon>
                          </Flex>
                        </Grid.Col>
                      </Grid>
                    );
                  })}
                </Paper>
              </Grid.Col>
              <Grid.Col span={5}>
                <Paper withBorder p="md">
                  <Flex justify="end">
                    <GolomtSvg />
                  </Flex>
                  <Divider my={"lg"} />
                  <Text fz={"lg"} fw={600} color="gray.9">
                    Төлбөл зохих
                  </Text>
                  <Space h={"lg"} />
                  <Flex gap={8}>
                    <Text fz={"lg"}>Огноо:</Text>
                    <Text color="golomt" fz={"lg"}>
                      2023-02-12
                    </Text>
                  </Flex>
                  <Space h={"lg"} />
                  <Flex gap={8}>
                    <Text fz={"lg"}>Хэтэрсэн хоног:</Text>
                    <Text color="golomt" fz={"lg"}>
                      {Math.abs(propsData?.remainingDays)} хоног
                    </Text>
                  </Flex>
                  <Space h={"lg"} />
                  <Text fz={"xl"} fw={600}>
                    Дүн:
                  </Text>
                  <Text color="golomt" fz={"32px"} fw={700}>
                    {currencyFormat(propsData?.amountToPay || 0)}
                  </Text>
                  <Space h={"lg"} />
                  <Text fz={"xl"} fw={600}>
                    Үлдэх дүн:
                  </Text>
                  <Text color="golomt" fz={"32px"} fw={700}>
                    {currencyFormat(
                      propsData?.amountToPay -
                        values?.schedule.reduce(function (a: any, b: any) {
                          return a + (b.amount || 0);
                        }, 0),
                    )}
                  </Text>
                </Paper>
              </Grid.Col>
              <Grid.Col>
                <Group position="apart">
                  <Button
                    onClick={() => {
                      setAction([]);
                    }}
                    variant="default">
                    Буцах
                  </Button>
                  <Button loading={loading} type="submit" variant="filled">
                    Амлалт илгээх
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
    </Paper>
  );
}
