import { Flex, Group, Text, Textarea } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string | any;
  required?: boolean;
  max?: number;
  minRows?: number;
  maxRows?: number;
  w?: any;
  h?: any;
};

export function TextareaField({ name, label, placeholder, disabled = false, required = false, max, maxRows, minRows }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <Textarea
        maxLength={max}
        minRows={minRows}
        maxRows={maxRows}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        withAsterisk
      />
      <Group align="center" position="apart" mt={"2px"}>
        <Text color="red" fz={"xs"}>
          {error && <Text>{error}</Text>}
        </Text>
        <Flex align="center" gap={4}>
          <Text color="dimmed" fz={"xs"}>
            {value?.length || 0}
          </Text>
          {max && (
            <Text color="dimmed" fz={"xs"}>
              / {max}
            </Text>
          )}
        </Flex>
      </Group>
    </>
  );
}
